import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BackgroundAnimation from './backgroundAnimation';
import { resetPathCrumb } from '../store/actions/actions';

function CountdownQuestion(props) {
    const dispatch = useDispatch();

    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const toast = useRef(null);

    useEffect(() => {
        async function trovaRisultato() {
            // Calcola la data di 15 giorni successivi da createAt
            const createAtDate = new Date(props?.resultResponseCountdown?.createAt);
            const targetDate = new Date(createAtDate.getTime() + 15 * 24 * 60 * 60 * 1000);

            function updateCountdown() {
                const now = new Date();
                const remainingTime = targetDate - now;

                if (remainingTime <= 0) {
                    // Se il tempo è scaduto, imposta tutto a 0
                    setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                    clearInterval(intervalId);
                    return;
                }

                const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
                const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

                // Aggiorna lo stato con il tempo rimanente
                setTimeLeft({ days, hours, minutes, seconds });
            }

            // Aggiorna il countdown ogni secondo
            const intervalId = setInterval(updateCountdown, 1000);
            updateCountdown(); // Esegui il calcolo immediatamente

            // Pulisci l'intervallo quando il componente viene smontato
            return () => clearInterval(intervalId);
        }

        trovaRisultato();
        dispatch(resetPathCrumb());
    }, [dispatch, props.resultResponseCountdown.createAt]);

    return (
        <>
            <BackgroundAnimation />
            <div>
                <div className="flex flex-col mt-16 items-center backdrop-blur-sm bg-gray-100/30 shadow-md w-screen h-screen">
                    <div className="text-center">
                        <p className='text-fluid1 mb-5'>Il prossimo questionario da compilare è tra:</p>
                        <div className='flex flex-row justify-center gap-6'>
                            <div className='flex flex-col items-center backdrop-blur-sm bg-gray-400/30 shadow-md p-3'>
                                <p className='text-fluid1'>{timeLeft.days.toString().length > 1 ? timeLeft.days : "0" + timeLeft.days}</p>
                            </div>
                            <div className='flex flex-col items-center backdrop-blur-sm bg-gray-400/30 min-w-10 shadow-md p-3'>
                                <p className='text-fluid1'>{timeLeft.hours.toString().length > 1 ? timeLeft.hours : "0" + timeLeft.hours}</p>
                            </div>
                            <div className='flex flex-col items-center backdrop-blur-sm bg-gray-400/30 min-w-10 shadow-md p-3'>
                                <p className='text-fluid1'>{timeLeft.minutes.toString().length > 1 ? timeLeft.minutes : "0" + timeLeft.minutes}</p>
                            </div>
                            <div className='flex flex-col items-center backdrop-blur-sm bg-gray-400/30 min-w-10 shadow-md p-3'>
                                <p className='text-fluid1'>{timeLeft.seconds.toString().length > 1 ? timeLeft.seconds : "0" + timeLeft.seconds}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default CountdownQuestion;
