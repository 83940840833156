import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APIRequest } from "../../services/axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CustomDataTable from "../../components/customDataTable";
import HeadPage from '../../components/headPage';
import UserForm from "../../common/userForm";
import AppointmentDetails from "../../common/appointmentDetails";
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { useWindowSize } from "../../utils/utils";

function Prestazioni() {
    const toast = useRef(null);
    const dt = useRef(null);
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [dialogNewEditAppointment, setDialogNewEditAppointment] = useState(false);
    const [deleteResultDialog, setDeleteResultDialog] = useState(false);
    const dispatch = useDispatch()
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'calendarService')
                .then(res => {
                    setResults(res.data.data);
                }).catch((e) => {
                    console.log(e)
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    const openDialog = (rowData) => {
        setDialog(true)
        setResult(rowData)
    }

    const onHideDialog = () => {
        setDialog(false)
        setResult(null)
    }

    const manageAppointment = (rowData, isNew) => {
        setDialogNewEditAppointment(true)
        setResult(!isNew ? rowData : null)
    }

    const hideDeleteResultDialog = () => {
        setDeleteResultDialog(false);
    };

    const confirmDeleteResult = (result) => {
        setResult(result);
        setDeleteResultDialog(true);
    };

    const deleteResult = async () => {

        await APIRequest("DELETE", `calendarService?id=${result?.id}`)
            .then(res => {
                let _results = results.filter((val) => val.id !== result.id);

                setResults(_results);
                setDeleteResultDialog(false);
                setResult(null);
                toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: "L'appuntamento è stato eliminato correttamente", life: 3000 });
            }).catch((e) => {
                console.log(e)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile eliminare l'appuntamento", life: 3000 });
            })
    };

    const onHideDialogNewEditAppointment = () => {
        setDialogNewEditAppointment(false)
    }

    const deleteResultDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="buttonCustom" outlined onClick={hideDeleteResultDialog} />
            <Button label="Si" icon="pi pi-check" className="buttonCustom" severity="danger" onClick={deleteResult} />
        </React.Fragment>
    );

    const fields = [
        {
            field: "idUser.username",
            header: "Nome utente",
            sortable: true,
            showHeader: true,
        },
        {
            field: "title",
            header: "Titolo",
            sortable: true,
            showHeader: true,
        },
        {
            field: "start",
            header: "Inizio",
            body: "startTemplate",
            sortable: true,
            showHeader: true,
        },
        {
            field: "end",
            header: "Fine",
            body: "endTemplate",
            sortable: true,
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: "Info",
            icon: <i className="pi pi-eye" />,
            handler: openDialog,
        },
        {
            name: "Modifica",
            icon: <i className="pi pi-pencil" />,
            handler: manageAppointment,
        },
        {
            name: "Elimina",
            icon: <i className="pi pi-times" />,
            handler: confirmDeleteResult,
        },
    ];

    /* const fieldsXLSX = ["Nome","Cognome","Email","Role"]
    const pathFieldsXLSX = ["idUser.idRegistry.firstName","idUser.idRegistry.lastName","idUser.username","idUser.role.label"] */

    const header = (
        <div className="flex flex-row justify-between items-center">
            {/* <Button className="buttonCustom" type="button" icon="pi pi-file-excel" label={`${width > 340 ? "Esporta XLS" : ""}`} severity="success" rounded onClick={() => exportExcel(fieldsXLSX, pathFieldsXLSX, results)}/> */}
            <Button className="buttonCustom" label={`${width > 340 ? "Nuovo" : ""}`} type="button" icon="pi pi-plus" rounded onClick={(rowData) => manageAppointment(rowData, true)} tooltip="Aggiungi appuntamento" data-pr-tooltip="Aggiungi appuntamento" tooltipOptions={{ position: 'top' }} />
        </div>
    );

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300 `}>
                <div className="p-3 min-[900px]:px-10 h-full flex flex-col justify-start items-center">
                    <CustomDataTable
                        dataKey="id"
                        loading={!results}
                        refer={dt}
                        value={results}
                        actionFields={actionFields}
                        fields={fields}
                        showHeader={true}
                        header={header}
                    />
                </div>
            </div>
            <Dialog visible={dialog} className="w-4/6 h-auto" header={"Dettagli appuntamento"} onHide={() => onHideDialog()}>
                <AppointmentDetails result={result} />
            </Dialog>
            <Dialog visible={dialogNewEditAppointment} className="w-4/5 h-1/2" header={`${result ? 'Modifica appuntamento' : 'Aggiungi appuntamento'}`} onHide={() => onHideDialogNewEditAppointment()}>
                <UserForm result={result} role="PATIENT" />
            </Dialog>
            <Dialog visible={deleteResultDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={() => <><i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1rem' }} />Conferma cancellazione</>} modal footer={deleteResultDialogFooter} onHide={hideDeleteResultDialog}>
                <div className="confirmation-content">
                    {result && (
                        <>
                            <span>
                                Vuoi davvero eliminare l'appuntamento <b>{`${result?.title} `}</b>
                            </span>
                            <br />
                            <span>
                                Dell'utente con email: <b>{`${result?.idUser?.username}`}</b>?
                            </span>
                        </>
                    )}
                </div>
            </Dialog>
        </div>
    );
}

export default Prestazioni;
