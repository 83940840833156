import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import CustomDataTable from './customDataTable';
import { APIRequest } from '../services/axios';
import { Toast } from 'primereact/toast';
import FeedbackForm from '../common/feedbackForm';
import { setPathCrumb } from '../store/actions/actions';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { exportExcel, handleDialogAndBreadcrumbs, useWindowSize } from '../utils/utils';
import RenderQuestionary from '../common/renderQuestionary';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import CountdownQuestion from './countdownQuestion';
function FeedbackFrame(props) {
    const dt = useRef(null);
    const toast = useRef(null);
    const dispatch = useDispatch()
    const [formQuest, setFormQuest] = useState(null)
    const [Dialog1, setDialog1] = useState(false)
    const [blockQuest, setBlockQuest] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null);
    const [resultResponseCountdown, setResultResponseCountdown] = useState(null)
    const [Users, setUsers] = useState(null)
    const [resultQuestionResponse, setResultQuestionResponse] = useState(null)
    const [resultQuestionary, setResultQuestionary] = useState(null)
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'feedbackService/questionResponse')
                .then(res => {
                    if (res.data.success) {
                        let x = {
                            "idUser": JSON.parse(window.localStorage.getItem("user")).id,
                            "idFeedback": res.data.data.find(el => el?.idUser?.id === JSON.parse(localStorage.getItem("user"))?.id)?.idFeedback ? parseInt(res.data.data.find(el => el?.idUser?.id === JSON.parse(localStorage.getItem("user"))?.id)?.idFeedback) : null,
                            "questionValue": []
                        }
                        setFormQuest(x)
                        if ((((new Date() - new Date(res.data.data.find(el => el?.idUser?.id === JSON.parse(localStorage.getItem("user"))?.id)?.createAt)) / (1000 * 60 * 60 * 24)) < 15) && (res.data.data.find(el => el?.idUser?.id === JSON.parse(localStorage.getItem("user"))?.id)?.questionValue?.length > 0)) {
                            setBlockQuest(true)
                            setResultResponseCountdown(res.data.data.find(el => el?.idUser?.id === JSON.parse(localStorage.getItem("user")).id))
                        }
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e)
                })
        }
        trovaRisultato();
    }, [props?.resultsQuestion])

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', `authService/user`)
                .then(res => {
                    let users = []
                    let filter = res.data.data
                    if(window.location.pathname === "/questionariValutazioneDelServizio"){
                        filter = res.data.data.filter(el => el.role.label === "PATIENT")
                    }
                    filter.forEach(element => {
                        let x = {
                            name: element.idRegistry.firstName + " " + element.idRegistry.lastName,
                            id: element.id
                        }
                        users.push(x)
                    });
                    setUsers(users)
                }).catch((e) => {
                    console.log(e)
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile reperire gli utenti.`, life: 3000 });
                })
        }
        trovaRisultato()
    }, [])

    const nuovoQuestionario = () => {
        props.setDialog2(true);
        dispatch(setPathCrumb(
            {
                "label": "Crea questionario",
                "template": () => <NavLink to={props.pathname}>Crea questionario</NavLink>
            }
        ))
    };

    const OpenDialog2 = async (e) => {
        props.setDialog1(true)
        setResultQuestionary(e)
        dispatch(setPathCrumb(
            {
                "label": "Info questionario",
                "template": () => <NavLink to={props.pathname}>Info questionario</NavLink>
            }
        ))
    }

    const DeleteQuestionary = async (e) => {
        await APIRequest('DELETE', `feedbackService?id=${e.id}`)
            .then(res => {
                if (res.data.success) {
                    let _resultsQuestion = [...props.resultsQuestion]
                    let filter = _resultsQuestion.filter(el => el.id !== e.id)
                    props.setResultsQuestion(filter)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            }).catch((e) => {
                console.log(e)
            })
    }

    const AssignQuestionary = async (e) => {
        setDialog1(true)
        let x = {
            "idUser": [],
            "idFeedback": e.id,
            "questionValue": []
        }
        setResultQuestionResponse(x)
    }

    const SaveAssignQuestionary = async (e) => {
        resultQuestionResponse.idUser.forEach(async element => {
            let x = {
                "idUser": element,
                "idFeedback": resultQuestionResponse?.idFeedback,
                "questionValue": resultQuestionResponse?.questionValue
            }
            await APIRequest('POST', `feedbackService/questionResponse`, x)
                .then(res => {
                    console.log(res.data.data)
                    setDialog1(false)
                }).catch((e) => {
                    console.log(e)
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile reperire gli utenti.`, life: 3000 });
                })
        });
    }

    const sendForm = async () => {
        let _formQuest = { ...formQuest }
        if ((props.resultsQuestion.find(obj => obj.id === _formQuest?.idFeedback))?.question?.length === _formQuest?.questionValue?.length) {
            await APIRequest('POST', `feedbackService/questionResponse`, _formQuest)
                .then(res => {
                    if (res.data.success) {
                        console.log(res.data.data)
                        setBlockQuest(true)
                        toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: "Questionario completato", life: 3000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e)
                })
        } else {
            toast.current.show({ severity: 'error', summary: 'Attenzione!', detail: "Prima di concludere il questionario compila tutti i campi", life: 3000 });
        }
    }

    const fields = [
        {
            field: "title",
            header: "Titolo",
            sortable: true,
            showHeader: true,
        },
        {
            field: "subTitle",
            header: "Sotto titolo",
            sortable: true,
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: "Info",
            icon: <i className="pi pi-eye" />,
            handler: OpenDialog2,
        },
        {
            name: "Assegna",
            icon: <i className="pi pi-plus" />,
            handler: AssignQuestionary,
        },
        {
            name: "Cancella",
            icon: <i className="pi pi-trash" />,
            handler: DeleteQuestionary,
        },
    ];

    const fieldsXLSX = ["Titolo", "Sotto titolo", "Descrizione"]
    const pathFieldsXLSX = ["title", "subTitle", "note"]

    const header = (
        <div className="flex flex-row justify-between items-center">
            <Button className="buttonCustom" type="button" icon="pi pi-file-excel" label={`${width > 340 ? "Esporta XLS" : ""}`} severity="success" rounded onClick={() => exportExcel(fieldsXLSX, pathFieldsXLSX, props.resultsQuestion)} />
            {((JSON.parse(window.localStorage.getItem("user")).role.label === "ADMIN" || JSON.parse(window.localStorage.getItem("user")).role.label === "SUPERVISOR")) &&
                <>
                    <Button className="buttonCustom max-[340px]:w-1/2" label={`${width > 340 ? "Crea questionario" : ""}`} type="button" icon="pi pi-plus" rounded onClick={() => nuovoQuestionario()} />
                </>
            }
        </div>
    );

    const indietro = () => {
        handleDialogAndBreadcrumbs(props.setDialog2, dispatch)
    }

    const indietro2 = () => {
        handleDialogAndBreadcrumbs(props.setDialog1, dispatch)
    }

    const userTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        const length = selectedUser ? selectedUser.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const usersSelector = (e) => {
        setSelectedUser(e)
        let _resultQuestionResponse = { ...resultQuestionResponse }
        _resultQuestionResponse.idUser = e.map(el => el.id)
        setResultQuestionResponse(_resultQuestionResponse)
    }

    const footerDialog1 = (
        <div className='flex flex-row justify-between w-full'>
            <Button className='buttonCustom' label='Chiudi' onClick={() => setDialog1(false)}></Button>
            <Button className='buttonCustom' label='Salva' onClick={() => SaveAssignQuestionary()}></Button>
        </div>
    )

    return (
        <div className={'w-full h-auto min-[1101px]:flex min-[1101px]:justify-center min-[1101px]:flex-row h-full'}>
            <div className='min-[1101px]:w-full min-[1101px]:flex min-[1101px]:flex-col'>
                <Toast ref={toast} />
                {JSON.parse(window.localStorage.getItem("user")).role.label === "ADMIN" || JSON.parse(window.localStorage.getItem("user")).role.label === "SUPERVISOR" ? (
                    <>
                        {props.dialog2 ? (
                            <div className='flex flex-col px-3'>
                                <div className='flex flex-row justify-between w-full p-4'>
                                    <Button className='buttonCustom' label='Indietro' onClick={() => indietro()}></Button>
                                </div>
                                <FeedbackForm resultsQuestionType={props.resultsQuestionType} resultsQuestion={props.resultsQuestion} setResultsQuestion={props.setResultsQuestion} dialog2={props.dialog2} setDialog2={props.setDialog2} />
                            </div>
                        ) : (
                            <>
                                {props.dialog1 ? (
                                    <div className='w-full h-full flex flex-col justify-start items-start bg-stone-100 px-3'>
                                        <div className='w-full flex flex-row justify-between p-4'>
                                            <Button className='buttonCustom' label='Indietro' onClick={() => indietro2()}></Button>
                                        </div>
                                        <div className='w-full h-full min-[1101px]:px-10 min-[1201px]:px-20 min-[1301px]:px-30 min-[1401px]:px-40 min-[1501px]:px-60 min-[1601px]:px-80'>
                                            <div className='w-full flex flex-col justify-start bg-white px-3 shadow shadox-xl border rounded-lg border-[#00b1b1] h-full'>
                                                <div className='bg-[#ffff88] my-5 p-3 mb-5 text-start flex flex-col gap-4'>
                                                    <div className='w-full text-xl font-bold'>{resultQuestionary && resultQuestionary?.title}</div>
                                                    <div className='w-full text-lg font-semibold'>{resultQuestionary && resultQuestionary?.subTitle}</div>
                                                    <div className='w-full '>{resultQuestionary && resultQuestionary?.note}</div>
                                                </div>
                                                <div className='flex flex-col gap-6'>
                                                    {resultQuestionary && resultQuestionary.question?.map((el, key) => {
                                                        return (
                                                            <React.Fragment key={key}>
                                                                <RenderQuestionary el={el} id={key} formQuest={formQuest} setFormQuest={setFormQuest} dialog1={props.dialog1} setDialog1={props.setDialog1} />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className=' px-3'>
                                        <CustomDataTable
                                            dataKey="id"
                                            loading={!props.resultsQuestion}
                                            refer={dt}
                                            value={props.resultsQuestion}
                                            actionFields={actionFields}
                                            fields={fields}
                                            showHeader={true}
                                            header={header}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <div className='w-full h-full flex flex-col justify-start items-center bg-stone-100'>
                        {blockQuest ?
                            <>
                                <CountdownQuestion resultResponseCountdown={resultResponseCountdown} />
                            </>
                            :
                            formQuest?.idFeedback ?
                                <>
                                    <div className={`${props?.resultsQuestion.find(el => el.id === formQuest?.idFeedback) ? "" : "hidden"} w-full flex flex-col justify-start bg-white px-3 border-x border-[#00b1b1] h-full`}>
                                        <div className='bg-[#ffff88] my-5 p-3 mb-5 text-start flex flex-col gap-4'>
                                            <div className='w-full text-xl font-bold'>{props?.resultsQuestion.find(el => el.id === formQuest?.idFeedback)?.title}</div>
                                            <div className='w-full text-lg font-semibold'>{props?.resultsQuestion.find(el => el.id === formQuest?.idFeedback)?.subTitle}</div>
                                            <div className='w-full '>{props?.resultsQuestion.find(el => el.id === formQuest?.idFeedback)?.note}</div>
                                        </div>
                                        <div className='flex flex-col gap-6'>
                                            {props?.resultsQuestion && props?.resultsQuestion.find(el => el.id === formQuest?.idFeedback)?.question?.map((el, key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                        <RenderQuestionary el={el} id={key} formQuest={formQuest} setFormQuest={setFormQuest} />
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            <Button className='buttonCustom' disabled={props?.resultsQuestion ? (props?.resultsQuestion.find(el => el.id === formQuest?.idFeedback)?.question?.length > formQuest?.questionValue?.length) : true} label='Invia' onClick={() => sendForm()}></Button>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className='mt-16'>
                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
                                </div>
                        }
                    </div>
                )}
            </div>
            <Dialog header="Assegna questionario" visible={Dialog1} onHide={() => { if (!Dialog1) return; setDialog1(false); }} footer={footerDialog1}>
                <div className='flex flex-col justify-center items-center'>
                    <MultiSelect value={selectedUser} options={Users} onChange={(e) => usersSelector(e.value)} optionLabel="name"
                        filter placeholder="Select user" itemTemplate={userTemplate} panelFooterTemplate={panelFooterTemplate} className='max-w-48' display="chip" />
                </div>
            </Dialog>
        </div>
    );
}

export default FeedbackFrame;
