import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { aprimenu, chiudimenu } from "../store/actions/actions.js";
import logoText from "../assets/img/logo_salus_text.png";
import logoPlain from "../assets/img/logo_salus_plain.png";
import { FaSignOutAlt, FaUserTie } from "react-icons/fa";
import { useAuth } from "./useAuth.js";
import { PanelMenu } from "primereact/panelmenu";
import { FaRegComment } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { GrBook, GrDocumentStore, GrDocumentUser, GrGroup, GrPlan, GrUserManager } from "react-icons/gr";
import { VscFeedback } from "react-icons/vsc";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { LiaUserNurseSolid } from "react-icons/lia";
import { MdNotifications, MdOutlinePlayLesson, MdSupervisorAccount } from "react-icons/md";
import { CgImport, CgProfile } from "react-icons/cg";
import { IoSchoolOutline } from "react-icons/io5";
import { RiFileUserLine } from "react-icons/ri";
import { useWindowSize } from "../utils/utils.js";


export function Sidebar() {
    let auth = useAuth();
    const dispatch = useDispatch();
    const isSidebarOpen = useSelector(state => state.menu.isSidebarOpen);
    const role = useSelector(state => state.menu.role);
    const username = useSelector(state => state.menu.username); // Assicurati che lo username sia memorizzato nello store Redux
    const navigate = useNavigate();
    const [width] = useWindowSize();
    const [expandedKeys, setExpandedKeys] = useState({});
    const [openMenus, setOpenMenus] = useState({});
    /* const menu = sideBarMenu(navigate); */

    const itemRenderer = (item) => {
        // Verifica se l'elemento corrente o uno dei suoi figli è selezionato
        const isSelected = window.location.pathname.toLowerCase().trim().replaceAll(" ", '') === ("/" + item.label.toLowerCase().trim().replaceAll(" ", ''));
        
        const hasSelectedChild = item.items && item.items.some((subItem) =>
            window.location.pathname.toLowerCase().trim().replaceAll(" ", '') === ("/" + subItem.label.toLowerCase().trim().replaceAll(" ", ''))
        );
    
        return (
            <span
                className={`menu-item ${isSelected || hasSelectedChild ? 'selected' : 'unselected'}
                text-lg flex flex-row items-center justify-between !px-1 cursor-pointer !pl-8 font-semibold
                ${isSidebarOpen ? "!py-0" : "!py-[0.64rem]"}
                ${item.parentKey ? "text-base font-normal pl-[3.5rem]" : ""}`}
            >
                {/* Icona e Label del menu */}
                <div className="flex flex-row items-center justify-start w-full">
                    <item.icon />
                    <span
                        className={`p-2 select-none text-start w-full ${!item?.items && "hover:underline"} ${isSidebarOpen ? "block" : "hidden"}`}
                        onClick={() => !item?.items && navigate("/" + item.label.toLowerCase())}
                    >
                        {item.label}
                    </span>
                </div>
    
                {/* Freccetta di destra del dropdown del menu */}
                <div className="pr-1">
                    {item?.items?.length > 0 &&
                        isSidebarOpen &&
                        ((openMenus[item.key] ? (
                            <IoMdArrowDropup size={20} />
                        ) : (
                            <IoMdArrowDropdown size={20} />
                        )))}
                </div>
            </span>
        );
    };

    useEffect(() => {
        async function trovaRisultato() {
            if (width < 899) {
                let _expandedKeys = { ...expandedKeys }
                _expandedKeys[0] = false
                setExpandedKeys(_expandedKeys)
                dispatch(chiudimenu());
            } else {
                dispatch(aprimenu());
            }
        }
        trovaRisultato()
    }, [width])

    useEffect(() => {
        async function trovaRisultato() {
            if (!isSidebarOpen) {
                setExpandedKeys({});
                setOpenMenus([])
            }
        }
        trovaRisultato()
    }, [isSidebarOpen])

    const toggleMenu = (key) => {
        setOpenMenus((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const menu = {
        "ADMIN": [
            {
                "key": "0",
                "icon": () => <FiUser size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Utenti",
                command: (e) => {
                    toggleMenu("0");
                    dispatch(aprimenu());
                },
                "template": itemRenderer,
                "items": [
                    {
                        "key": "0-1",
                        "icon": () => <LiaUserNurseSolid size={28} className={`w-8`} />,
                        "label": "Operatori",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/operatori")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-2",
                        "icon": () => <GrDocumentUser size={20} className={`w-8`} />,
                        "label": "Pazienti",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/pazienti")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-3",
                        "icon": () => <GrUserManager size={20} className={`w-8`} />,
                        "label": "HR",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/hr")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-4",
                        "icon": () => <FaUserTie size={20} className={`w-8`} />,
                        "label": "Contabili",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/contabili")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-5",
                        "icon": () => <MdSupervisorAccount size={20} className={`w-8`} />,
                        "label": "Supervisore",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/supervisore")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-6",
                        "icon": () => <GrDocumentUser size={20} className={`w-8`} />,
                        "label": "Segretari",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/segretari")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-7",
                        "icon": () => <RiFileUserLine size={20} className={`w-10`} />,
                        "label": "Candidati",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/candidati")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                ]
            },
            {
                "key": "1",
                "icon": () => <GrGroup size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Recruiting",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/recruiting")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <IoSchoolOutline size={28} className={`w-8 pr-[0.5rem]`} />,
                "label": "Formazione",
                command: (e) => {
                    toggleMenu("2");
                    dispatch(aprimenu());
                },
                "template": itemRenderer,
                "items": [
                    {
                        "key": "0-1",
                        "icon": () => <MdOutlinePlayLesson size={23} className={`w-8`} />,
                        "label": "Corsi",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/corsi")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-2",
                        "icon": () => <GrBook size={20} className={`w-8`} />,
                        "label": "Crea corso",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/creaCorso")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                ]
            },
            {
                "key": "3",
                "icon": () => <GrDocumentStore size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Rendicontazione",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/rendicontazione")
                },
                "template": itemRenderer,
            },
            {
                "key": "4",
                "icon": () => <GrPlan size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Prestazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/prestazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "5",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    toggleMenu("5");
                    dispatch(aprimenu());
                },
                "template": itemRenderer,
                "items": [
                    {
                        "key": "0-3",
                        "icon": () => <VscFeedback size={20} className={`w-8`} />,
                        "label": "Questionari valutazione della struttura",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/questionariValutazioneDellaStruttura")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-4",
                        "icon": () => <VscFeedback size={20} className={`w-8`} />,
                        "label": "Questionari valutazione del servizio",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/questionariValutazioneDelServizio")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-1",
                        "icon": () => <VscFeedback size={20} className={`w-8`} />,
                        "label": "Valutazione della struttura",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/valutazioneDellaStruttura")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-2",
                        "icon": () => <VscFeedback size={20} className={`w-8`} />,
                        "label": "Valutazione del servizio",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/valutazioneDelServizio")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                ]
            },
            {
                "key": "6",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "7",
                "icon": () => <CgImport size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "import",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/import")
                },
                "template": itemRenderer,
            },
            {
                "key": "8",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "OPERATOR": [
            {
                "key": "1",
                "icon": () => <GrPlan size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Prestazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/prestazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "5",
                "icon": () => <IoSchoolOutline size={28} className={`w-8 pr-[0.5rem]`} />,
                "label": "Corsi",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/corsi")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/feedback")
                },
                "template": itemRenderer,
            },
            {
                "key": "3",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "4",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "PATIENT": [
            {
                "key": "1",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/feedback")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "3",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "HR": [
            {
                "key": "0",
                "icon": () => <GrGroup size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Recruiting",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/recruiting")
                },
                "template": itemRenderer,
            },
            {
                "key": "1",
                "icon": () => <RiFileUserLine size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Candidati",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/candidati")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/feedback")
                },
                "template": itemRenderer,
            },
            {
                "key": "3",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "4",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "ACCOUNTANT": [
            {
                "key": "0",
                "icon": () => <GrDocumentStore size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Rendicontazione",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/rendicontazione")
                },
                "template": itemRenderer,
            },
            {
                "key": "1",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/feedback")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "3",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "SUPERVISOR": [
            {
                "key": "0",
                "icon": () => <FiUser size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Utenti",
                command: (e) => {
                    toggleMenu("0");
                    dispatch(aprimenu());
                },
                "template": itemRenderer,
                "items": [
                    {
                        "key": "0-1",
                        "icon": () => <LiaUserNurseSolid size={28} className={`w-8`} />,
                        "label": "Operatori",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/operatori")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-2",
                        "icon": () => <GrDocumentUser size={20} className={`w-8`} />,
                        "label": "Pazienti",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/pazienti")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-3",
                        "icon": () => <GrUserManager size={20} className={`w-8`} />,
                        "label": "HR",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/hr")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-4",
                        "icon": () => <FaUserTie size={20} className={`w-8`} />,
                        "label": "Contabili",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/contabili")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-6",
                        "icon": () => <GrDocumentUser size={20} className={`w-8`} />,
                        "label": "Segretari",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/segretari")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-7",
                        "icon": () => <RiFileUserLine size={20} className={`w-10`} />,
                        "label": "Candidati",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/candidati")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                ]
            },
            {
                "key": "1",
                "icon": () => <GrGroup size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Recruiting",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/recruiting")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <GrBook size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Formazione",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/formazione")
                },
                "template": itemRenderer,
            },
            {
                "key": "3",
                "icon": () => <GrDocumentStore size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Rendicontazione",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/rendicontazione")
                },
                "template": itemRenderer,
            },
            {
                "key": "4",
                "icon": () => <GrPlan size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Prestazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/prestazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "5",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    toggleMenu("5");
                    dispatch(aprimenu());
                },
                "template": itemRenderer,
                "items": [
                    {
                        "key": "0-1",
                        "icon": () => <VscFeedback size={20} className={`w-8`} />,
                        "label": "Valutazione della struttura",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/valutazioneDellaStruttura")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                    {
                        "key": "0-2",
                        "icon": () => <VscFeedback size={20} className={`w-8`} />,
                        "label": "Valutazione del servizio",
                        command: (e) => {
                            dispatch(aprimenu());
                            navigate("/valutazioneDelServizio")
                        },
                        "template": itemRenderer,
                        "parentKey": "0",
                    },
                ]
            },
            {
                "key": "6",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "7",
                "icon": () => <CgImport size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "import",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/import")
                },
                "template": itemRenderer,
            },
            {
                "key": "8",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "SECRETARY": [
            {
                "key": "0",
                "icon": () => <FiUser size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Operatori",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/operatori")
                },
                "template": itemRenderer,
            },
            {
                "key": "1",
                "icon": () => <GrDocumentStore size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Pazienti",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/pazienti")
                },
                "template": itemRenderer,
            },
            {
                "key": "2",
                "icon": () => <GrPlan size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Prestazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/prestazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "3",
                "icon": () => <VscFeedback size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Feedback",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/feedback")
                },
                "template": itemRenderer,
            },
            {
                "key": "4",
                "icon": () => <MdNotifications size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Notifiche",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/notifiche")
                },
                "template": itemRenderer,
            },
            {
                "key": "5",
                "icon": () => <FaRegComment size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Conversazioni",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/conversazioni")
                },
                "template": itemRenderer,
            },
            {
                "key": "90",
                "icon": () => <CgProfile size={23} className={`w-8 pr-[0.5rem]`} />,
                "label": "Profilo",
                command: (e) => {
                    dispatch(aprimenu());
                    navigate("/profilo")
                },
                "template": itemRenderer,
            },
        ],
        "CANDIDATE": [

        ],
    }

    const toggleSidebar = () => {
        if (isSidebarOpen) {
            let _expandedKeys = { ...expandedKeys }
            _expandedKeys[0] = false
            setExpandedKeys(_expandedKeys)
            dispatch(chiudimenu());
        } else {
            dispatch(aprimenu());
        }
    };


    const handleIconClick = (e, path) => {
        if (!isSidebarOpen) {
            toggleSidebar();
        } else {
            navigate(path);
        }
    };

    const truncateText = (text, maxLength) => {
        if (text) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        }
        return '';
    };

    return (
        <div className={`min-[900px]:h-screen ${isSidebarOpen ? "max-[899px]:max-h-[60vh]" : "max-[899px]:h-[calc(10vh)]"} bg-white text-black flex flex-col border-[#00b1b14D] min-[900px]:border-r max-[899px]:border-b max-[899px]:items-center sticky justify-start`} >
            <div className={`absolute z-[9999] top-1/2 ${isSidebarOpen ? "max-[899px]:top-[98%]" : "max-[899px]:top-[77%]"} min-[900px]:-right-5 max-[899px]:w-screen buttonMenu`} onClick={() => toggleSidebar()}>
                <Button icon={`w-auto p-3 rounded-full border border-[#008c8d] bg-[#00b1b1] hover:bg-[#008c8d] max-[899px]:bg-[#008c8d] text-white ${isSidebarOpen ? (width > 899 ? "pi pi-chevron-left" : " pi pi-chevron-up") : (width > 899 ? "pi pi-chevron-right" : "pi pi-chevron-down")}`}></Button>
            </div>
            <div className="flex items-center justify-center min-[900px]:pt-7 max-[899px]:py-4 px-2">
                <img
                    src={isSidebarOpen ? logoText : logoPlain}
                    alt="Logo"
                    className={`logo-image ${isSidebarOpen ? 'block' : ''} h-8 cursor-pointer select-none`}
                /* onClick={toggleSidebar} */
                />
            </div>

            <div className={`${isSidebarOpen ? "max-[899px]:overflow-y-scroll" : "max-[899px]:hidden"}`}>
                {role !== "CANDIDATE" &&
                    <div className={`max-h-[60vh] overflow-y-scroll max-[899px]:mt-3 max-[899px]:flex max-[899px]:flex-row max-[899px]:justify-center min-[900px]:mt-10 justify-start transation-all duration-200 ease-in-out ${isSidebarOpen ? "w-[16rem] max-[899px]:h-full overflow-y" : "w-24 max-[899px]:h-0.5"}`}>
                        <div className={`max-[899px]:flex max-[899px]:flex-col max-[899px]:justify-center max-[899px]:h-[30vh] min-[900px]:max-h-[calc(100vh-300px)] ${isSidebarOpen ? "max-[899px]:!block" : "max-[899px]:!hidden"}`}>
                            <PanelMenu model={menu[role]} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} />
                        </div>
                    </div>
                }
                {isSidebarOpen &&
                    <div className="flex flex-row items-center justify-center gap-2 min-[900px]:my-4">
                        <div className="min-[900px]:my-4 max-[899px]:mt-4">
                            <div className="text-center text-gray-700 select-none">{role}</div>
                            <div className="text-center text-gray-500 select-none">{truncateText(username, 20)}</div>
                        </div>
                        {role === "ADMIN" &&
                            <div className="min-[900px]:my-4">
                                <Button icon={`pi pi-cog`} onClick={(e) => handleIconClick(e, '/configuratore')}></Button>
                            </div>
                        }
                    </div>
                }
                <div className={`p-4 px-5 max-[899px]:mb-2 ${isSidebarOpen ? "max-[899px]:block" : "max-[899px]:hidden pl-1 pr-6 flex flex-row justify-end"}`}>
                    <button
                        onClick={() => {
                            auth.signout(() => navigate("/"));
                        }}
                        className={`!flex items-center justify-center buttonCustom select-none gap-2  ${isSidebarOpen ? "!w-full" : "!w-9/12"} !px-2`}
                    >
                        <FaSignOutAlt className="select-none" />
                        {isSidebarOpen && <span className="select-none">Esci</span>}
                    </button>
                </div>
            </div>
        </div>
    );
}
