import { useDispatch, useSelector } from "react-redux";
import { setPathCrumb } from "../store/actions/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { capitalizePathName, handleDialogAndBreadcrumbs } from "../utils/utils";

function HeadPage(props) {
    const dispatch = useDispatch()
    const path = useSelector(state => state.menu.path)
    useEffect(() => {
        if (!path.find(el => el.label === window.location.pathname.split("/")[1].split(/(?=[A-Z])/).join(" "))) {
            dispatch(setPathCrumb(
                {
                    "label": window.location.pathname,
                    "template": () => <NavLink to={window.location.pathname} onClick={() => {
                        handleDialogAndBreadcrumbs(props.setDialog, dispatch);
                    }}>
                        {capitalizePathName(window.location.pathname)}
                    </NavLink>,
                    "props": {}
                }
            ))
        }
    }, [dispatch, path, props])
    return (
        <div className="w-full h-auto bg-[#00b1b1] sticky top-0 z-10">
            <BreadCrumb className="first-letter:uppercase font-semibold text-white text-fluid1 p-4 bg-transparent text-start select-none" model={path} />
            {/* <div className="first-letter:uppercase font-semibold text-white text-4xl p-4 text-start">{window.location.pathname.split("/")[1].split(/(?=[A-Z])/).join(" ")}</div> */}
        </div>
    )
}

export default HeadPage;
