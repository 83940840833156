import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import BackgroundAnimation from "../components/backgroundAnimation";
import { useAuth } from "../provider/useAuth";
import logo_salus from "../assets/img/logo_salus.svg";
import { APIRequest } from '../services/axios';
import ErrorDialog from '../components/errorDialog';
import { useDispatch } from 'react-redux';
import { setRole } from '../store/actions/actions.js';

function Login() {
  const navigate = useNavigate();
  const auth = useAuth();
  const messagesEndRef = useRef(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function handleLogin(event) {
    event?.preventDefault();
    const credentials = { username, password };

    try {
      const res = await APIRequest('POST', 'authService', credentials);
      if (res.data.success) {
        const token = res.data.token;
        const user = res.data.user;

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        let path = '';
        switch (user.role.label) {
          case "ADMIN": path = "/operatori"; break;
          case "OPERATOR": path = "/prestazioni"; break;
          case "PATIENT": path = "/feedback"; break;
          case "SUPERVISOR": path = "/operatori"; break;
          case "HR": path = "/recruiting"; break;
          case "ACCOUNTANT": path = "/rendicontazione"; break;
          case "SECRETARY": path = "/operatori"; break;
          case "CANDIDATE": path = "/candidatura"; break;
          default: path = "/";
        }

        dispatch(setRole(user.role.label, user.username));

        auth.signin(user, () => {
          navigate(path, { state: { username: user.username }, replace: true });
        });
      } else {
        setError(res.data.message);
      }
    } catch (e) {
      const errorMessage = e.response?.data || 'Invalid credentials';
      setError(errorMessage);
    }
  }

  const closeErrorDialog = () => {
    setError(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevenire il comportamento predefinito del tasto Invio (nuova linea)
      handleLogin()
    }
  };

  return (
    <>
      <BackgroundAnimation />
      <div className="h-screen flex items-center justify-center relative">
        <div ref={messagesEndRef} />
        <div className="backdrop-blur-sm bg-white/30 p-8 rounded shadow-md max-[1500px]:w-4/12 max-[1000px]:w-6/12 max-[500px]:w-5/6 w-2/12 z-10">
          <div className="flex justify-center mb-6">
            <img src={logo_salus} alt="Logo Salus" className="h-12" />
          </div>
          <h2 className="mb-6 text-2xl text-center">Login</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold mb-2 text-start">Username</label>
              <input
                type="text"
                name="username"
                value={username}
                onKeyDown={handleKeyDown}
                onChange={(e) => setUsername(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-semibold mb-2 text-start">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onKeyDown={handleKeyDown}
                onChange={(e) => setPassword(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-customTeal hover:bg-customTealHover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      {error && <ErrorDialog message={error} onClose={closeErrorDialog} />}
    </>
  );
}

export default Login;
