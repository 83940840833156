import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { APIRequest } from '../../services/axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { useWindowSize } from '../../utils/utils';
import { Steps } from 'primereact/steps';
import { MdOndemandVideo, MdTextFields } from 'react-icons/md';
import { GoRepoPush } from 'react-icons/go';
import { InputTextarea } from 'primereact/inputtextarea';

function CreaCorso() {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [subDescription, setSubDescription] = useState("")
    const [totalSize, setTotalSize] = useState(0);
    const [file, setFile] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [contentLesson, setContentLesson] = useState([])
    const [contentCopy, setContentCopy] = useState(null)
    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const [editingContent, setEditingContent] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const toast = useRef(null);
    const fileRef = useRef(null);
    const [width] = useWindowSize();
    const dispatch = useDispatch();

    const itemRenderer = (item, itemIndex) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? '#00b1b1' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';
        const borderColor = isActiveItem ? 'solid 0.3px #00b1b1' : 'solid 0.3px var(--text-color-secondary)';

        return (
            <span
                className="flex flex-row gap-2 items-center justify-center items-center rounded-full p-3 z-999"
                style={{ backgroundColor: backgroundColor, color: textColor, border: borderColor, marginTop: '-25px' }}
            >
                {itemIndex === 0 ?
                    <MdTextFields size={28} className={`w-8`} />
                    :
                    itemIndex === 1 ?
                        <MdOndemandVideo size={28} className={`w-8`} />
                        :
                        itemIndex === 2 ?
                            <GoRepoPush size={28} className={`w-8`} />
                            :
                            <></>
                }
                {width > 600 &&
                    item.label
                }
            </span>
        );
    };

    const items = [
        {
            label: 'Intestazione',
            template: (item) => itemRenderer(item, 0)
        },
        {
            label: 'Lezioni',
            template: (item) => itemRenderer(item, 1)
        },
        {
            label: 'Pubblicazione',
            template: (item) => itemRenderer(item, 2)
        },
    ];

    useEffect(() => {
        /* dispatch(chiudimenu()); */
        dispatch(resetPathCrumb());
    }, [dispatch]);

    const newContent = () => {
        setSubTitle("")
        setSubDescription("")
        setFile(null)
        setTotalSize(0)
        let _activeIndex = activeIndex + 1
        setActiveIndex(_activeIndex)
    }
    const goBack = () => {
        let _activeIndex = activeIndex - 1
        setActiveIndex(_activeIndex)
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;
        setFile(files[0])
        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        setFile(null)
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setFile(null)
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                {chooseButton}
                {cancelButton}
                <div className="flex max-[380px]:flex-col items-center min-[380px]:gap-3 max-[400px]:w-9/12">
                    <span>{formatedValue}</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small className='text-gray-400'>{props.formatSize}</small>
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5"
                    style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}
                    onClick={() => {
                        const input = fileRef.current && fileRef.current.getInput();
                        if (input) input.click();
                    }}
                ></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Carica file
                </span>
            </div>
        );
    };

    const addContent = () => {
        let x = [...contentLesson]
        x.push({ "id": Math.random(), "subTitle": subTitle, "subDescription": subDescription, "file": file })
        setContentLesson(x)
        setSubTitle("")
        setSubDescription("")
        setFile(null)
        fileRef.current.clear();
        setTotalSize(0)
    }

    const handleConfirmUpload = async () => {
        let _resultCourse;
        for (let i = 0; i < contentLesson.length; i++) {
            let el = contentLesson[i];
            const formData = new FormData();
            formData.append('file', new Blob([el?.file], { type: el?.file?.type }), el?.file?.name);
            formData.append('title', el?.file?.name);
            formData.append('url', "/");
            formData.append('path', "/attachments");
            let y = {
                "title": title,
                "description": description,
                "contents": [
                    {
                        "title": el?.subTitle,
                        "description": el?.subDescription,
                        "url": "/",
                        "type": el?.file?.type
                    }
                ]
            };

            if (i > 0) {
                await APIRequest('POST', 'coursesService/content/upload?contentId=' + _resultCourse.id, formData)
                    .then(res => {
                        if (res.data.success) {
                            console.log(res);
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                    });
            } else {
                await APIRequest('POST', 'coursesService', y)
                    .then(async res => {
                        if (res.data.success) {
                            _resultCourse = res.data.data;
                            await APIRequest('POST', 'coursesService/content/upload?contentId=' + _resultCourse.id, formData)
                                .then(res => {
                                    if (res.data.success) {
                                        console.log(res);
                                    } else {
                                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                                });
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Corso non creato`, life: 3000 });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Corso non creato`, life: 3000 });
                    });
            }
        }
        setTitle("")
        setDescription("")
        setContentLesson([])
        setEditingContent(null)
        setActiveIndex(0)
    };

    const removeContentElement = (el) => {
        const x = [...contentLesson]
        x.splice(x.indexOf(el), 1)
        setContentLesson(x)
    }

    // Inizio del trascinamento
    const handleDragStart = (index) => {
        setDraggedItemIndex(index);
    };

    // Permetti il trascinamento sopra altri elementi
    const handleDragOver = (e) => {
        e.preventDefault(); // Necessario per consentire il drop
    };

    // Quando un elemento viene rilasciato
    const handleDrop = (index) => {
        const newContentLesson = [...contentLesson];
        const draggedItem = newContentLesson[draggedItemIndex];

        // Rimuove l'elemento trascinato dalla posizione precedente
        newContentLesson.splice(draggedItemIndex, 1);

        // Inserisce l'elemento alla nuova posizione
        newContentLesson.splice(index, 0, draggedItem);

        setContentLesson(newContentLesson);
    };

    // Funzione per gestire la modifica
    const handleEditClick = (content) => {
        setEditingContent(content);
        setContentCopy(content)
        if (content.file && content.file.data) {
            const byteCharacters = atob(content.file.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const fileUrl = URL.createObjectURL(blob);
            setFileUrl(fileUrl);  // Aggiorna lo stato per impostare l'URL nell'iFrame
        }
    };

    // Funzione per gestire il salvataggio delle modifiche
    const handleSaveEdit = () => {
        let _contentLesson = [...contentLesson]
        for (let i = 0; i < _contentLesson.length; i++) {
            if (_contentLesson[i].id === editingContent.id) {
                _contentLesson[i].subTitle = editingContent.subTitle;
                _contentLesson[i].subDescription = editingContent.subDescription;
                _contentLesson[i].file = file ? file : _contentLesson[i].file
                break;
            }
        }
        setContentLesson(_contentLesson);
        setFile(null)
        fileRef.current.clear();
        setTotalSize(0)
        setEditingContent(null); // Resetta il form di modifica
    };

    const chooseOptions = { icon: 'pi pi-fw pi-plus', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined h-7 w-7 hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined h-7 w-7' };

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className="w-full relative h-full">
                <Steps className='py-10' model={items} activeIndex={activeIndex} />
                {activeIndex === 0 &&
                    <div className='w-full flex flex-col'>
                        <div className='flex flex-row justify-start w-full px-10 pt-9 font-semibold uppercase'></div>
                        <div className='flex flex-col justify-start items-center gap-6 mt-5 min-h-[70vh] border-b border-[#00b1b14D]'>
                            <div className='max-[600px]:w-full w-7/12 flex flex-col items-start gap-2'>
                                <div className=' font-semibold uppercase'>Titolo corso</div>
                                <InputText className="w-full select-none p-2 border focus:shadow-none" id='title' placeholder="Titolo lezione" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className='max-[600px]:w-full w-7/12 flex flex-col items-start gap-2'>
                                <div className=' font-semibold uppercase'>Descrizione corso</div>
                                <InputTextarea className="w-full select-none p-2 border focus:shadow-none" id='description' rows={10} placeholder="Descrizione lezione" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                        </div>
                        <div className='w-full flex flex-row items-center justify-end mt-5 px-10'>
                            <Button className="buttonCustom !p-3 !px-6" icon="pi pi-arrow-right" label={`${width > 450 ? "Avanti" : ""}`} disabled={!title || !description} onClick={() => newContent()}></Button>
                        </div>
                    </div>
                }
                {activeIndex === 1 &&
                    <>
                        <div className='flex flex-row justify-start w-full px-10 pt-3 font-semibold uppercase'>Nuova lezione</div>
                        <div className='flex max-[899px]:flex-col min-[900px]:flex-row gap-6 mt-5 min-h-[70vh] border-b border-[#00b1b14D]'>
                            <div className='flex gap-4 min-[900px]:flex-col max-[600px]:flex-col max-[899px]:flex-row px-6 max-[899px]:w-full w-4/12'>
                                <div className='flex flex-col items-start gap-2 max-[899px]:w-6/12 max-[600px]:w-full min-[900px]:w-full px-4'>
                                    <div className=' font-semibold uppercase text-start'>Titolo lezione</div>
                                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='subTitle' placeholder="Titolo lezione" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                                </div>
                                <div className='flex flex-col items-start gap-2 max-[899px]:w-6/12 max-[600px]:w-full min-[900px]:w-full px-4'>
                                    <div className=' font-semibold uppercase text-start'>Descrizione lezione</div>
                                    <InputTextarea className="w-full select-none p-2 border focus:shadow-none" id='subDescription' rows={10} placeholder="Descrizione lezione" value={subDescription} onChange={(e) => setSubDescription(e.target.value)} />
                                </div>
                            </div>
                            <div className='flex flex-row justify-center max-[899px]:w-full w-8/12'>
                                <div className='flex flex-col px-10'>
                                    <FileUpload
                                        ref={fileRef}
                                        name="demo[]"
                                        url="/api/upload"
                                        accept="*"
                                        onUpload={onTemplateUpload}
                                        onSelect={onTemplateSelect}
                                        onError={onTemplateClear}
                                        onClear={onTemplateClear}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={itemTemplate}
                                        emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions}
                                        cancelOptions={cancelOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-row items-center justify-between mt-5 px-10">
                            <Button className="buttonCustom !p-3 !px-6" icon="pi pi-arrow-left" label={`${width > 450 ? "Indietro" : ""}`} onClick={() => goBack()}></Button>
                            <Button type="button" label={`${width > 450 ? "Aggiungi lezione" : ""}`} icon="pi pi-plus" className="buttonCustom !p-3 !px-6" disabled={!subTitle || !subDescription || !file} onClick={() => addContent()} />
                            <Button className="buttonCustom !p-3 !px-6" icon="pi pi-arrow-right" label={`${width > 450 ? "Avanti" : ""}`} disabled={contentLesson.length === 0} onClick={() => newContent()}></Button>
                        </div>
                    </>
                }
                {activeIndex === 2 &&
                    <>
                        <div className='flex flex-row justify-start w-full px-10 pt-3 font-semibold uppercase'>{editingContent ? "Lezione del corso" : "Lezioni del corso"}</div>
                        {!editingContent ?
                            <div className="min-h-[70vh] border-b border-[#00b1b14D]">
                                <div className='flex flex-row flex-wrap gap-3 w-full px-10 overflow-x-scroll mt-5'>
                                    {contentLesson.map((el, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <div className='flex flex-row items-center gap-3 border border-[#00b1b14D] p-5 px-2'
                                                    draggable
                                                    onDragStart={() => handleDragStart(key)}
                                                    onDragOver={handleDragOver}
                                                    onDrop={() => handleDrop(key)}
                                                >
                                                    {(key + 1) + ") "}
                                                    {el.subTitle}
                                                    <i className='pi pi-arrows-alt buttonCustom !p-1 !rounded-full'></i>
                                                    <i className='pi pi-pencil buttonCustom !p-1 !rounded-full cursor-pointer' onClick={() => handleEditClick(el)}></i>
                                                    <i className='pi pi-trash buttonCustom !p-1 !rounded-full' onClick={() => removeContentElement(el)}></i>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            <div className="min-h-[70vh] border-b border-[#00b1b14D]">
                                <div className="flex flex-col mt-5">
                                    <div className='flex max-[899px]:flex-col min-[900px]:flex-row gap-6 mt-5'>
                                        <div className="flex gap-4 min-[900px]:flex-col max-[600px]:flex-col max-[899px]:flex-row px-6 max-[899px]:w-full w-4/12">
                                            <div className="flex flex-col items-start gap-2 max-[899px]:w-6/12 max-[600px]:w-full min-[900px]:w-full px-4">
                                                <div className="font-semibold uppercase text-start">Titolo lezione</div>
                                                <InputText className="w-full select-none p-2 border focus:shadow-none" placeholder="Titolo lezione" value={editingContent.subTitle} onChange={(e) => setEditingContent({ ...editingContent, subTitle: e.target.value })} />
                                            </div>
                                            <div className="flex flex-col items-start gap-2 max-[899px]:w-6/12 max-[600px]:w-full min-[900px]:w-full px-4">
                                                <div className="font-semibold uppercase text-start">Descrizione lezione</div>
                                                <InputText className="w-full select-none p-2 border focus:shadow-none" placeholder="Descrizione lezione" value={editingContent.subDescription} onChange={(e) => setEditingContent({ ...editingContent, subDescription: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-center max-[899px]:w-full w-8/12">
                                            <div className="flex flex-row max-[899px]:flex-col px-10 gap-6">
                                                <div className="mb-4">
                                                    {/* Visualizza file caricato */}
                                                    {editingContent.file && (
                                                        <div className="flex flex-col items-center">
                                                            <div className="text-gray-500">File attuale:</div>
                                                            {fileUrl && (
                                                                <div className="mt-5">
                                                                    <iframe
                                                                        src={fileUrl}
                                                                        title="File Preview"
                                                                        className="w-full h-96 border-2 border-gray-300"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <FileUpload
                                                    ref={fileRef}
                                                    name="demo[]"
                                                    url="/api/upload"
                                                    accept="*"
                                                    onUpload={onTemplateUpload}
                                                    onSelect={onTemplateSelect}
                                                    onError={onTemplateClear}
                                                    onClear={onTemplateClear}
                                                    headerTemplate={headerTemplate}
                                                    itemTemplate={itemTemplate}
                                                    emptyTemplate={emptyTemplate}
                                                    chooseOptions={chooseOptions}
                                                    cancelOptions={cancelOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {editingContent ?
                            <div className="w-full flex flex-row items-center justify-between mt-5 px-10">
                                <Button className="buttonCustom !p-3 !px-6" onClick={() => setEditingContent(null)} icon="pi pi-arrow-left" label={`${width > 450 ? "Annulla" : ""}`}></Button>
                                <Button className="buttonCustom !p-3 !px-6" disabled={contentCopy.subTitle === editingContent.subTitle && contentCopy.subDescription === editingContent.subDescription && !file} onClick={handleSaveEdit} icon="pi pi-save" label={`${width > 450 ? "Salva Modifiche" : ""}`}></Button>
                            </div>
                            :
                            <div className="w-full flex flex-row items-center justify-between mt-5 px-10">
                                <Button className="buttonCustom !p-3 !px-6" icon="pi pi-arrow-left" label={`${width > 450 ? "Indietro" : ""}`} onClick={() => goBack()}></Button>
                                <Button type="button" label={`Pubblica corso`} icon="pi pi-save" className="buttonCustom !p-3 !px-6" disabled={contentLesson.length === 0} onClick={() => handleConfirmUpload()} />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default CreaCorso;
