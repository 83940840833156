import { useDispatch } from "react-redux";
import Profilo from "../../components/profile";
import HeadPage from "../../components/headPage";
import { useEffect } from "react";
import { resetPathCrumb } from "../../store/actions/actions";

function Profile() {
    const dispatch = useDispatch();
    const result = JSON.parse(window.localStorage.getItem("user"))

    useEffect(() => {
        dispatch(resetPathCrumb())
    }, [dispatch])

    return (
        <>
            <div className="w-full h-full flex flex-row items-center justify-start ">
                <div className={`h-full w-full`}>
                    <Profilo result={result} role={result.role.id} />
                </div>
            </div>
        </>
    )
}

export default Profile;
