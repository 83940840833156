import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { APIRequest, locale } from '../../services/axios';
import imageBG from "../../assets/img/working.jpg";
import { Dialog } from 'primereact/dialog';
import { useWindowSize } from '../../utils/utils';
import { IoMdPlay } from 'react-icons/io';

function Corsi() {
    const [courses, setCourses] = useState(null);
    const [result, setResult] = useState(null)
    const [course, setCourse] = useState(null);
    const [openDialog1, setOpenDialog1] = useState(false)
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [completed, setCompleted] = useState(false)
    const [videoProgress, setVideoProgress] = useState(0);
    const [progressId, setProgressId] = useState(null); // Stato per memorizzare l'id del progresso
    const [showModal, setShowModal] = useState(false); // Stato per mostrare la modale
    const toast = useRef(null);
    const videoRef = useRef(null);
    const cardRefs = useRef([]);
    const [width] = useWindowSize();
    const dispatch = useDispatch();

    useEffect(() => {
        if (courses && cardRefs.current.length > 0) {
            cardRefs.current.forEach((ref) => {
                if (ref) {
                    ref.classList.add('from-right'); // Aggiungi la classe da destra
    
                    const observer = new IntersectionObserver(
                        ([entry]) => {
                            if (entry.isIntersecting) {
                                ref.classList.add('enter');
                            } else {
                                ref.classList.remove('enter');
                            }
                        },
                        { threshold: 0.1 }
                    );
                    observer.observe(ref);
                }
            });
        }
    }, [courses, cardRefs.current]);

    useEffect(() => {
        dispatch(resetPathCrumb());
        async function trovaRisultato() {
            await APIRequest('GET', `coursesService`)
                .then(res => {
                    if (res.data.success) {
                        setCourses(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare la formazione.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare la formazione.`, life: 3000 });
                });
        }
        trovaRisultato();
    }, [dispatch]);

    /* useEffect(() => {
        if (width < 1000) {
            dispatch(chiudimenu());
        }
    }, [width]) */

    const downloadCertificato = () => {
        if (course && course.certificate && course.certificate.id) {
            const url = `${locale}documentsService/download/${course.certificate.id}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', course.certificate.title);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Certificato non disponibile o URL non trovato');
        }
    };

    const handleSelectCourse = async (el) => {
        setCourse(el);
        setSelectedLesson(el.contents[0].id);
        await fetchProgress(el.id, el.contents[0].id);
    };

    const handleLessonClick = async (lessonId) => {
        setSelectedLesson(lessonId);
        await fetchProgress(course.id, lessonId);
    };

    const fetchProgress = async (courseId, contentId) => {
        const idUser = JSON.parse(localStorage.user).id;
        await APIRequest('GET', `coursesService/progress?idUser=${idUser}&idCourse=${courseId}&idContent=${contentId}`)
            .then(res => {
                if (res.data.success && res.data.data) {
                    setVideoProgress(res.data.data.score);
                    setProgressId(res.data.data.id); // Imposta l'id del progresso
                    setShowModal(true); // Mostra la modale per chiedere se riprendere dal punto salvato
                } else {
                    setVideoProgress(0);
                    setProgressId(null); // Resetta l'id del progresso
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile ottenere i progressi.`, life: 3000 });
            });
    };

    const handleProgress = async (status) => {
        const idUser = JSON.parse(localStorage.user).id;
        const idCourse = course.id;
        const idContent = selectedLesson;
        const score = videoRef.current.currentTime; // Ottieni il minutaggio corrente del video

        const progressData = {
            status,
            score,
        };

        if (progressId) {
            // Se esiste un progresso, fai una PUT per aggiornarlo
            await APIRequest('PUT', `coursesService/progress?id=${progressId}`, progressData)
                .then(res => {
                    if (!res.data.success) {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                });
        } else {
            // Se non esiste un progresso, fai una POST per crearlo
            await APIRequest('POST', `coursesService/progress`, { ...progressData, idUser, idCourse, idContent })
                .then(res => {
                    if (res.data.success) {
                        setProgressId(res.data.data.id); // Imposta l'id del progresso creato
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                });
        }
    };

    const handleResume = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = videoProgress;
        }
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const deleteCourse = async () => {
        await APIRequest('DELETE', `coursesService?id=` + result.id,)
            .then(res => {
                if (res.data.success) {
                    setOpenDialog1(false)
                    setCourse(null);
                    setSelectedLesson(null);
                    let filter = courses.filter(el => el.id !== result.id)
                    setCourses(filter)
                    setResult(null)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il corso.`, life: 3000 });
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il corso.`, life: 3000 });
            });
        result.contents.forEach(async element => {
            await APIRequest('DELETE', `coursesService/content?id=` + element.id,)
                .then(res => {
                    if (res.data.success) {

                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il contenuto del corso.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il contenuto del corso.`, life: 3000 });
                });
        });
    }

    const handleDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label="Sì" onClick={handleResume} className="buttonCustom p-button-success mr-2" />
                <Button label="No" onClick={handleCancel} className="buttonCustom p-button-secondary" />
            </React.Fragment>
        )
    }
    const handleDialogFooter2 = () => {
        return (
            <React.Fragment>
                <Button label="Sì" onClick={() => deleteCourse()} className="buttonCustom p-button-success mr-2" />
                <Button label="No" onClick={() => { setOpenDialog1(false); setResult(null) }} className="buttonCustom p-button-secondary" />
            </React.Fragment>
        )
    }

    const goBack = () => {
        setSelectedLesson(null);
        setVideoProgress(0);
        setProgressId(null);
        setShowModal(false);
    
        // Temporaneamente rimuovi le card dal DOM per forzare un nuovo ciclo di rendering
        setCourses(null);
    
        // Re-imposta i corsi con un leggero ritardo per far ripartire l'animazione
        setTimeout(() => {
            setCourses([...courses]); // Forza il re-rendering delle card
        }, 100); // 100ms dovrebbe essere sufficiente per forzare un nuovo ciclo di rendering
    
        // Resetta l'animazione delle card
        cardRefs.current.forEach((ref) => {
            if (ref) {
                ref.classList.remove('enter');  // Rimuovi la classe 'enter' per far ripetere l'animazione
                ref.classList.remove('from-right');  // Rimuovi anche 'from-right'
                // Forza il reflow per assicurare che l'animazione si resetti
                void ref.offsetWidth;  
                ref.classList.add('from-right');
            }
        });
    };
    

    return (
        <div className="flex flex-col h-full relative overflow-x-hidden">
            <Toast ref={toast} />
            <HeadPage />
            <div className="w-full relative h-full">
                <div className="relative w-full flex flex-row-reverse transation-all duration-200">
                    {!selectedLesson &&
                        <div className={`w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 items-start px-5 py-5 border-[#00b1b14D] border-l transition-all duration-200 relative`}>
                            {courses && (
                                <>
                                    {courses.map((el, key) => {
                                        return (
                                            <div
                                                ref={(el) => (cardRefs.current[key] = el)} // Aggiungiamo il ref qui
                                                className='flex flex-col gap-1 border-md relative h-auto bg-white group'
                                                key={key}
                                                onClick={() => handleSelectCourse(el)}
                                            >
                                                {/* Immagine adattabile con overlay */}
                                                <div className='relative flex flex-col items-center'>
                                                    {(JSON.parse(window.localStorage.getItem("user"))?.role?.label === "ADMIN" ||
                                                        JSON.parse(window.localStorage.getItem("user"))?.role?.label === "SUPERVISOR") && (
                                                            <Button
                                                                className='absolute top-0 right-0 flex flex-row items-center justify-center bg-[#00b1b1] py-2 text-white rounded-full z-[3] m-2'
                                                                icon='pi pi-trash'
                                                                onClick={(e) => { e.stopPropagation(); setOpenDialog1(true); setResult(el); }}
                                                            ></Button>
                                                        )}

                                                    {/* Immagine */}
                                                    <img
                                                        src={imageBG}
                                                        alt='Logo'
                                                        className={`select-none w-full h-auto object-cover max-h-48`}
                                                    />

                                                    {/* Overlay opaco con icona Play */}
                                                    <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 flex justify-center items-center transition-opacity duration-300">
                                                        <div className='rounded-full bg-white p-3'><IoMdPlay className='ml-1' size={30} /></div>
                                                    </div>
                                                </div>

                                                {/* Testo e dettagli corso */}
                                                <div className='flex flex-col items-start w-full'>
                                                    <div className='w-full text-start text-lg font-bold'>{el.title.length >= 55 ? el.title.slice(0, 55) + "..." : el.title}</div>
                                                    <div className='w-full flex justify-start items-center gap-3 text-sm text-gray-600'>
                                                        <i className='pi pi-video text-sm'></i>
                                                        <p>{el.total_duration} min</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    }
                    {selectedLesson &&
                        <div className={`w-full h-[calc(100vh-150px)] relative`}>
                            <Button className="buttonCustom !absolute !top-1 !left-2 !p-3 !px-6" icon="pi pi-arrow-left" label={`${width > 450 ? "Indietro" : ""}`} onClick={() => goBack()}></Button>
                            {course &&
                                <div className={`w-full h-2/3 border-[#00b1b14D] border-b`}>
                                    <div className='h-[93%] w-full flex flex-row justify-center items-center'>
                                        {(selectedLesson && course.contents.find(obj => obj.id === selectedLesson)?.attachments.length > 0) &&
                                            <video
                                                controls
                                                className="w-auto h-full"
                                                ref={videoRef}
                                                onPlay={() => handleProgress(1)} // Stato "in corso"
                                                onPause={() => handleProgress(1)} // Stato "in corso"
                                                onEnded={() => handleProgress(2)} // Stato "completato"
                                            >
                                                <source src={`${locale}coursesService/attachments/${course.contents.find(obj => obj.id === selectedLesson)?.attachments[0]?.filename}`} type="video/mp4" onError={(e) => console.log(e)} />
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                    </div>
                                    <hr className='w-full border-[#00b1b14D]' />
                                    <div className="h-[7%] w-full flex flex-row justify-between items-center px-7">
                                        <div className="flex flex-row items-center space-x-4">
                                            {course.contents.map((el) => (
                                                <p
                                                    key={el.id}
                                                    onClick={() => handleLessonClick(el.id)}
                                                    className={`text-sm font-medium cursor-pointer ${selectedLesson === el.id ? 'text-blue-500 underline' : 'text-gray-700'}`}
                                                >
                                                    {el.title}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                            {course &&
                                <div className='w-full h-1/3 relative overflow-y-scroll'>
                                    <div className='sticky top-0'>
                                        <div className='px-7 py-4 '>
                                            <div className='text-2xl text-start'>{course.titolo}</div>
                                            <div className='flex flex-row gap-10'>
                                                <div className='flex flex-col items-start justify-cente pt-5'>
                                                    <div className='text-lg font-bold'>{course.total_duration}</div>
                                                    <div className='text-sm text-gray-400'>Durata totale</div>
                                                </div>
                                                <div className='flex flex-col items-start justify-cente pt-5'>
                                                    <div className='text-lg font-bold'>{course.contents.length}</div>
                                                    <div className='text-sm text-gray-400'>Lezioni totali</div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='w-full border-[#00b1b14D]' />
                                        <div className={`flex flex-row ${width <= 640 ? "flex-col" : ""} px-7 py-4`}>
                                            <div className='font-semibold w-1/12'>Certificati</div>
                                            <div className={`inline-block w-0.5 self-stretch bg-[#00b1b14D] mx-10 ${width <= 640 ? "hidden" : ""}`}></div>
                                            <div className='w-11/12 flex flex-col gap-3'>
                                                <p className='text-start flex flex-row'>Ottieni il certificato completando l'intero corso</p>
                                                <Button label='Riscatta certificato' className={`!cursor-no-drop bg-transparent border ${completed ? "border-[#00b1b1] text-[#00b1b1]" : "border-gray-400 text-gray-400"} sm:w-5/md:w-5/12 lg:w-4/12 xl:w-3/12 p-2`} onClick={() => downloadCertificato()}></Button>
                                            </div>
                                        </div>
                                        <hr className='w-full border-[#00b1b14D]' />
                                        <div className={`flex flex-row ${width <= 640 ? "flex-col" : ""} px-7 py-4`}>
                                            <div className='font-semibold w-1/12'>Descrizione</div>
                                            <div className={`inline-block w-0.5 self-stretch bg-[#00b1b14D] mx-10 ${width <= 640 ? "hidden" : ""}`}></div>
                                            <div className='text-start w-11/12'>
                                                {course.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <Dialog
                visible={showModal}
                onHide={handleCancel}
                header="Riprendere dal punto salvato?"
                footer={handleDialogFooter}
            >
                <p>Vuoi riprendere la lezione dal punto salvato ({videoProgress.toFixed(2)} secondi)?</p>
            </Dialog>
            <Dialog
                visible={openDialog1}
                onHide={() => setOpenDialog1(false)}
                header="Cancellazione del corso"
                footer={handleDialogFooter2}
            >
                <p>Sei sicuro di voler cancellare il corso {result?.title} ?</p>
            </Dialog>
        </div>
    );
}

export default Corsi;
