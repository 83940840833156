import React, { createContext, useEffect, useRef, useState } from "react";
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { RequireAuth } from "./provider/requireAuth";
import { useDispatch } from 'react-redux';
import { setRole } from './store/actions/actions.js';
import { AuthProvider } from './provider/authProvider';
import { APIRequest } from "./services/axios.js";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { onMessage } from "firebase/messaging";
import { Toast } from "primereact/toast";
import Layout from './provider/layout';
import Login from './routes/login';
import Operatori from "./routes/admin/operatori.js";
import Feedback from "./routes/admin/feedback.js";
import Recruiting from "./routes/admin/recruiting.js";
import Rendicontazione from "./routes/admin/rendicontazione.js";
import Pazienti from "./routes/admin/pazienti.js";
import Prestazioni from "./routes/admin/prestazioni.js";
import Notifiche from "./routes/admin/notifiche.js";
import Configuratore from "./routes/admin/configuratore.js";
import HR from "./routes/admin/hr.js";
import Contabili from "./routes/admin/contabili.js";
import Supervisore from "./routes/admin/supervisore.js";
import Segretari from "./routes/admin/segretari.js";
import ImportFile from "./components/importFile.js";
import AvviaConversazione from "./components/avviaConversazione.js";
import ValutazioneDellaStruttura from "./routes/admin/valutazioneDellaStruttura.js";
import ValutazioneDelServizio from "./routes/admin/valutazioneDelServizio.js";
import QuestionariValutazioneDellaStruttura from "./routes/admin/questionariValutazioneDellaStruttura.js";
import QuestionariValutazioneDelServizio from "./routes/admin/questionariValutazioneDelServizio.js";
import 'primereact/resources/themes/saga-blue/theme.css';  // Assicurati che questo sia incluso
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './style/App.css';
import CreaCorso from "./routes/admin/creaCorso.js";
import PageCandidato from "./routes/admin/pageCandidato.js";
import Candidati from "./routes/admin/candidati.js";
import LavoraConNoi from "./routes/lavoraConNoi.js";
import Profile from "./routes/admin/profilo.js";
import Corsi from "./routes/admin/corsi.js";

export let AuthContext = createContext();

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Qualcosa è andato storto:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

function App() {
  const dispatch = useDispatch();
  const toastBC = useRef(null);
  const [token, setToken] = useState(null);
  const [user, setuser] = useState(null);
  let username = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.username : undefined
  let idUser = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.id : null

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: 'BD0Ab5dxmGz9zMBZAQyCX18ObwA_erBcJ9SAL0v0X2VtFmGER283k5lAgIa3U1PlOU_c7ejvKwqJYLwqqLOLa0Q',
      });
      if (idUser) {
        console.log("Token generated : ", token);
        let body = {
          idUser,
          fcmToken: token
        }
        await APIRequest('POST', `notificationService/saveToken`, body)
          .then(res => {
            console.log(res)
          }).catch((e) => {
            console.log(e);
          })
      }
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  onMessage(messaging, (payload) => {
    toastBC.current.show({
      severity: 'success',
      summary: 'Can you send me the report?',
      life: 3000,
      content: () => (
        <div className="flex flex-col items-left" style={{ flex: '1' }}>
          <div className="flex items-start font-bold text-lg">{payload.notification.title}</div>
          <div className="flex items-start font-medium text-md my-3 text-900">{payload.notification.body}</div>
        </div>
      )
    });
  });

  useEffect(() => {
    requestPermission();
  })

  useEffect(() => {
    async function trovaRisultato() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);

                getToken(messaging, { vapidKey: 'BD0Ab5dxmGz9zMBZAQyCX18ObwA_erBcJ9SAL0v0X2VtFmGER283k5lAgIa3U1PlOU_c7ejvKwqJYLwqqLOLa0Q' })
                    .then(async (currentToken) => {
                        if (currentToken) {
                            if (idUser) {
                                console.log("Token generated : ", currentToken);
                                let body = {
                                    idUser,
                                    fcmToken: currentToken
                                };
                                await APIRequest('POST', `notificationService/saveToken`, body)
                                    .then(res => {
                                        console.log(res);
                                    }).catch((e) => {
                                        console.log(e);
                                    });
                            }
                            console.log('Token:', currentToken);
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    })
                    .catch((err) => {
                        console.error('An error occurred while retrieving token. ', err);
                    });
            })
            .catch((err) => {
                console.error('Service Worker registration failed: ', err);
            });
    }
    }
    trovaRisultato();
  }, [idUser]);


  useEffect(() => {
    async function trovaRisultato() {
      let username = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.username : undefined
      if (username) {
        await APIRequest('GET', `authService/checktoken?username=${username}`)
          .then(res => {
            setToken(localStorage.getItem('token'));
            setuser(username);
          }).catch((e) => {
            console.log(e);
            localStorage.setItem('user', '')
            window.location.reload()
          })
      } else {
        localStorage.setItem('user', '')
        localStorage.setItem('token', '')
      }
    }
    trovaRisultato();
  }, [user, token, username])


  useEffect(() => {
    const storedUser = localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user')) : undefined;
    if (storedUser && storedUser.role) {
      dispatch(setRole(storedUser.role.label));
    }
  }, [dispatch]);

  if (!token && username) {
    return (
      <div className="App">
        <div className="bodyRow d-flex flex-column justify-content-center">
          {/* <Loader /> */}
        </div>
      </div>
    )
  }

  return (
    <div className="App w-full">
      <Toast ref={toastBC} position="bottom-center" />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/lavoraConNoi" element={<LavoraConNoi />} />
            <Route path="/candidatura" element={<RequireAuth token={token}><PageCandidato /></RequireAuth>} />
            <Route element={<Layout />}>
              <Route path="/operatori" element={<RequireAuth token={token}><Operatori /></RequireAuth>} />
              <Route path="/pazienti" element={<RequireAuth token={token}><Pazienti /></RequireAuth>} />
              <Route path="/profilo" element={<RequireAuth token={token}><Profile /></RequireAuth>} />
              <Route path="/HR" element={<RequireAuth token={token}><HR /></RequireAuth>} />
              <Route path="/contabili" element={<RequireAuth token={token}><Contabili /></RequireAuth>} />
              <Route path="/supervisore" element={<RequireAuth token={token}><Supervisore /></RequireAuth>} />
              <Route path="/segretari" element={<RequireAuth token={token}><Segretari /></RequireAuth>} />
              <Route path="/corsi" element={<RequireAuth token={token}><Corsi /></RequireAuth>} />
              <Route path="/creaCorso" element={<RequireAuth token={token}><CreaCorso /></RequireAuth>} />
              <Route path="/feedback" element={<RequireAuth token={token}><Feedback /></RequireAuth>} />
              <Route path="/valutazioneDellaStruttura" element={<RequireAuth token={token}><ValutazioneDellaStruttura /></RequireAuth>} />
              <Route path="/valutazioneDelServizio" element={<RequireAuth token={token}><ValutazioneDelServizio /></RequireAuth>} />
              <Route path="/questionariValutazioneDellaStruttura" element={<RequireAuth token={token}><QuestionariValutazioneDellaStruttura /></RequireAuth>} />
              <Route path="/questionariValutazioneDelServizio" element={<RequireAuth token={token}><QuestionariValutazioneDelServizio /></RequireAuth>} />
              <Route path="/recruiting" element={<RequireAuth token={token}><Recruiting /></RequireAuth>} />
              <Route path="/candidati" element={<RequireAuth token={token}><Candidati /></RequireAuth>} />
              <Route path="/rendicontazione" element={<RequireAuth token={token}><Rendicontazione /></RequireAuth>} />
              <Route path="/prestazioni" element={<RequireAuth token={token}><Prestazioni /></RequireAuth>} />
              <Route path="/notifiche" element={<RequireAuth token={token}><Notifiche /></RequireAuth>} />
              <Route path="/configuratore" element={<RequireAuth token={token}><Configuratore /></RequireAuth>} />
              <Route path="/import" element={<RequireAuth token={token}><ImportFile /></RequireAuth>} />
              <Route path="/conversazioni" element={<RequireAuth token={token}><AvviaConversazione /></RequireAuth>} />
            </Route>
          </Routes>
        </AuthProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
