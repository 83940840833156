
import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { MdOutlineQuestionMark, MdTitle } from 'react-icons/md';
import { InputTextarea } from 'primereact/inputtextarea';
import { TbFileDescription } from 'react-icons/tb';
import { PiSubtitles } from 'react-icons/pi';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { APIRequest } from '../services/axios';
import { handleDialogAndBreadcrumbs } from '../utils/utils';
import { useDispatch } from 'react-redux';

export default function FeedbackForm(props) {
    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [description, setDescription] = useState("")
    const [questionValue, setQuestionValue] = useState([])
    const [questionValues, setQuestionValues] = useState([])
    const [btnFlag, setBtnFlag] = useState(false)
    const toast = useRef(null);
    const dispatch = useDispatch()
    const Default5Answer = [
        { "key": "0", "label": "Insufficiente", },
        { "key": "1", "label": "Sufficiente", },
        { "key": "2", "label": "Buono", },
        { "key": "3", "label": "Ottimo", },
        { "key": "4", "label": "Eccellente", },
    ]
    /* const Default4Answer = [
        {"key": "0", "label": "Non soddisfatto",},
        {"key": "1", "label": "Poco soddisfatto",},
        {"key": "2", "label": "Soddisfatto",},
        {"key": "3", "label": "Molto soddisfato",},
    ] */


    const AddQuest = () => {
        let x = {
            "questionContent": "",
            "key": Math.random(),
            "noteFlag": false,
            "note": "",
            "labels": []
        }
        setBtnFlag(true)
        let _questionValue = [...questionValue]
        _questionValue.push(x)
        setQuestionValue(_questionValue)
    }
    const CreaQuestionario = async () => {
        let _questionValues = [...questionValues]
        let body = {
            "title": title,
            "subTitle": subTitle,
            "note": description,
            "question": _questionValues,
            "questionaryTypeId": props.resultsQuestionType
        }
        if (title || subTitle || description) {
            await APIRequest('POST', 'feedbackService/createBulk', body)
                .then(async res => {
                    console.log(res.data.data)
                    if (res.data.success) {
                        let _resultsQuestion = [...props.resultsQuestion]
                        _resultsQuestion.push(res.data.data)
                        props.setResultsQuestion(_resultsQuestion)
                        handleDialogAndBreadcrumbs(props.setDialog2, dispatch)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e)
                })
        } else {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di salvare riempire tutti i campi obbligatori", life: 3000 });
        }
    }
    const SaveQuest = () => {
        if (questionValue.find(el => el.questionContent === "")) {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di salvare inserisci la domanda", life: 3000 });
        } else if (questionValue.find(el => el.labels.length <= 0)) {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di salvare inserisci le risposte", life: 3000 });
        } else if (questionValue.find(el => el.labels.find(ele => ele.label === ""))) {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di salvare compila le riposte", life: 3000 });
        } else {
            let _questionValues = [...questionValues]
            _questionValues.push(questionValue[0])
            setQuestionValues(_questionValues)
            setBtnFlag(false)
            setQuestionValue([])
        }
    }
    const removeValues = (el) => {
        let _questionValues = [...questionValues]
        _questionValues = _questionValues.filter(ele => ele.key !== el.key)
        setQuestionValues(_questionValues)
    }
    const setTitleQuest = (e, key) => {
        let _questionValue = [...questionValue]
        _questionValue[key].questionContent = e.target.value
        setQuestionValue(_questionValue)
    }
    const setAnswer = (e, index, key) => {
        let _questionValue = [...questionValue]
        _questionValue[key].labels[index].label = e.target.value
        setQuestionValue(_questionValue)
    }
    const removeQuest = (element) => {
        let _questionValue = [...questionValue]
        setBtnFlag(false)
        _questionValue = _questionValue.filter(el => el.key !== element.key)
        setQuestionValue(_questionValue)
    }
    const removeAnwer = (value, element) => {
        let _element = { ...element }
        let _questionValue = [...questionValue]
        _element = _element.labels.filter(el => el.key !== value.key)
        let findIndex = _questionValue.findIndex(el => el.key === element.key)
        _questionValue[findIndex].labels = _element
        setQuestionValue(_questionValue)
    }
    const removeNoteBox = (element) => {
        let _questionValue = [...questionValue]
        let _element = { ...element }
        _element.noteFlag = false
        _element.note = ""
        let findIndex = _questionValue.findIndex(el => el.key === element.key)
        _questionValue[findIndex] = _element
        setQuestionValue(_questionValue)
    }
    const AddNoteBox = (element) => {
        if (element.questionContent === "") {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di aggiungere la motivazione inserisci la domanda", life: 3000 });
        } else {
            let _questionValue = [...questionValue]
            let _element = { ...element }
            _element.noteFlag = true
            let findIndex = _questionValue.findIndex(el => el.key === element.key)
            _questionValue[findIndex] = _element
            setQuestionValue(_questionValue)
        }
    }
    const writeNote = (e, element) => {
        let _questionValue = [...questionValue]
        let _element = { ...element }
        _element.note = e
        let findIndex = _questionValue.findIndex(el => el.key === element.key)
        _questionValue[findIndex] = _element
        setQuestionValue(_questionValue)
    }
    const AddAnswer = (element) => {
        if (element.questionContent === "") {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di aggiungere una risposta inserisci la domanda", life: 3000 });
        } else if (element.labels.find(el => el.label === "")) {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di aggiungere una nuova risposta compila prima quella precedente", life: 3000 });
        } else {
            let _questionValue = [...questionValue]
            let _element = { ...element }
            let x = {
                "key": Math.random(),
                "label": "",
            }
            _element.labels.push(x)
            let findIndex = _questionValue.findIndex(el => el.key === element.key)
            _questionValue[findIndex] = _element
            setQuestionValue(_questionValue)
        }
    }

    const Add5DefaultAnswer = (element) => {
        if (element.questionContent === "") {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di aggiungere una risposta inserisci la domanda", life: 3000 });
        } else if (element.labels.find(el => el.label === "")) {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: "Prima di aggiungere una nuova risposta compila prima quella precedente", life: 3000 });
        } else {
            let _questionValue = [...questionValue]
            let _element = { ...element }
            _element.labels = Default5Answer
            let findIndex = _questionValue.findIndex(el => el.key === element.key)
            _questionValue[findIndex] = _element
            setQuestionValue(_questionValue)
        }
    }

    return (
        <div className='w-full h-full flex flex-col mt-10 px-3'>
            <Toast ref={toast} />
            <div className='w-full flex max-[1299px]:flex-col flex-row justify-around gap-10'>
                <div className='max-[1299px]:w-full w-1/2 flex flex-col items-center gap-10 bg-stone-100 rounded-lg border  shadow shadow-xl'>
                    <div className="w-full p-5">
                        <label htmlFor="title" className="select-none font-bold block flex flex-row items-center justify-start mb-2 gap-2">
                            <MdTitle size={25} /><span className='text-lg'>Titolo*</span>
                        </label>
                        <InputText className="w-full select-none p-2 border focus:shadow-none" id='title' placeholder="titolo" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="w-full p-5">
                        <label htmlFor="subTitle" className="select-none font-bold block flex flex-row items-center justify-start mb-2 gap-2">
                            <PiSubtitles size={25} /><span className='text-lg'>Sotto titolo*</span>
                        </label>
                        <InputText className="w-full select-none p-2 border focus:shadow-none" id='subTitle' placeholder="sotto titolo" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                    </div>
                    <div className="w-full p-5">
                        <label htmlFor="description" className="select-none font-bold block flex flex-row items-center justify-start mb-2 gap-2">
                            <TbFileDescription size={25} /><span className='text-lg'>Descrizione*</span>
                        </label>
                        <InputTextarea className="w-full select-none p-2 border focus:shadow-none" rows={5} id='description' placeholder="descrizione" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>
                <div className='max-[1299px]:w-full w-1/2 flex flex-col items-center gap-10 bg-stone-100 rounded-lg border  shadow shadow-xl'>
                    <div className="w-full p-5">
                        <label htmlFor="quesiton" className="select-none font-bold block flex flex-row items-center justify-between mb-2">
                            <div className='flex flex-row items-center'>
                                <MdOutlineQuestionMark size={25} /><span className='text-lg'>Domanda</span>
                            </div>
                            {btnFlag ?
                                <Button className='buttonCustom !m-0 !py-1' label='Salva' icon="pi pi-save" onClick={() => SaveQuest()} />
                                :
                                <Button className='buttonCustom !m-0 !py-1' label='Aggiungi' icon="pi pi-plus" onClick={() => AddQuest()} />
                            }
                        </label>
                        <div className='flex flex-col'>
                            {questionValue.map((element, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <hr className='border border-[#00b1b14D] my-5' />
                                        <div className='flex flex-col items-end'>
                                            <div className='flex flex-row max-[450px]:flex-col justify-around mb-5 w-full'>
                                                <div className='flex flex-row'><Button className='buttonCustom max-[450px]:!w-full' label='Motivazione' icon="pi pi-file-edit" onClick={() => AddNoteBox(element)} /></div>
                                                <div className='flex flex-row'><Button className='buttonCustom max-[450px]:!w-full' label='Risposta' icon="pi pi-plus" onClick={() => AddAnswer(element)} /></div>
                                                <div className='flex flex-row'><Button className='buttonCustom max-[450px]:!w-full' label='Risposte default' icon="pi pi-plus" onClick={() => Add5DefaultAnswer(element)} /></div>
                                            </div>
                                            <div className='flex flex-row w-full'>
                                                <InputText className="w-11/12 select-none p-2 border focus:shadow-none" id='domanda' placeholder="domanda" value={element.questionContent} onChange={(e) => setTitleQuest(e, key)} />
                                                <div className='w-1/12'><Button className='buttonCustom !m-0 !p-0 !flex !flex-row items-center justify-center !w-full !h-full !rounded-l-none' icon="pi pi-trash" onClick={() => removeQuest(element)} /></div>
                                            </div>
                                            <div className='flex flex-col mt-5 w-full gap-2'>
                                                {element?.labels.map((value, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className='w-full flex flex-row items-center'>
                                                                <div className='w-1/12 flex flex-row justify-center'>{index + 1 + " )"}</div>
                                                                <div className='w-11/12 flex flex-row'>
                                                                    <InputText className="w-11/12 select-none p-2 border focus:shadow-none" id='risposta' placeholder="risposta" value={value.label} onChange={(e) => setAnswer(e, index, key)} />
                                                                    <div className='w-1/12'><Button className='buttonCustom !m-0 !p-0 !flex !flex-row items-center justify-center !w-full !h-full !rounded-l-none' icon="pi pi-trash" onClick={() => removeAnwer(value, element)} /></div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                            {element.noteFlag &&
                                                <div className='w-full flex flex-row mt-3'>
                                                    <InputTextarea className="w-11/12 select-none p-2 border focus:shadow-none" id='description' placeholder="descrizione" value={element.note} onChange={(e) => writeNote(e.target.value, element)} />
                                                    <div className='w-1/12'><Button className='buttonCustom !m-0 !p-0 !flex !flex-row items-center justify-center !w-full !h-full !rounded-l-none' icon="pi pi-trash" onClick={() => removeNoteBox(element)} /></div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {questionValues.length > 0 &&
                <div className='w-full mt-8 bg-stone-100 rounded-lg border  shadow shadow-xl'>
                    {questionValues.map((el, key) => {
                        return (
                            <React.Fragment key={key}>
                                <div className='flex flex-col items-center gap-1'>
                                    <div className='flex flex-row items-center gap-10 w-full my-3 px-5'>
                                        <div className=' text-startfont-bold'>{key + 1 + " ) "}{el.questionContent}</div>
                                        <div className='flex flex-row'><Button className='buttonCustom !py-1' label='Rimuovi' icon="pi pi-trash" onClick={() => removeValues(el)} /></div>
                                    </div>
                                    <div className='w-full grid grid-cols-5 max-[700px]:grid-cols-3 items-center justify-start px-5'>
                                        {el.labels.map((element, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className='flex flex-col w-full'>
                                                        <div className='border border-[#00b1b14D] p-4 bg-white'>{element.label}</div>
                                                        <div className={`border border-[#00b1b14D] p-4 bg-white`}></div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                    {el.note &&
                                        <div className='w-5/6 flex flex-col items-start justify-center '>
                                            <div className='mt-6 mb-3'>{el.note}</div>
                                            <InputTextarea className="w-full select-none p-2 border focus:shadow-none" id='motivazione' disabled={true} placeholder="motivazione" />
                                        </div>
                                    }
                                </div>
                            </React.Fragment>
                        )
                    })}
                    <div className='flex flex-row justify-around my-5 w-full'>
                        <div className='flex flex-row'><Button className='buttonCustom' label='Crea Questionario' icon="pi pi-save" onClick={() => CreaQuestionario()} /></div>
                    </div>
                </div>
            }
        </div>
    )
}
