import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import CustomDataTable from '../../components/customDataTable';
import { APIRequest } from '../../services/axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { exportExcel, useWindowSize } from '../../utils/utils';
import CaricamentoDocs from '../../components/caricamentoDocs';

function Recruiting() {
    const toast = useRef(null);
    const dt = useRef(null);
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const [resultDialog, setResultDialog] = useState(null);
    const dispatch = useDispatch()
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest("GET", `recruitmentService/`)
                .then(res => {
                    if (res.data.success) {
                        setResults(res.data.data)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e)
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    const openDocumentsDialog = (rowData) => {
        setResult(rowData)
        setResultDialog(true)
    }
    const approveCandidatesDialog = (rowData) => {
        
    }
    const rejectCandidatesDialog = (rowData) => {
        
    }

    const fields = [
        {
            field: "data",
            header: "Data",
            body: "dataFormat",
            sortable: true,
            showHeader: true,
        },
        {
            field: "firstName",
            header: "Nome",
            body: "firstName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "lastName",
            header: "Cognome",
            body: "lastName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "email",
            header: "Email",
            body: "username",
            sortable: true,
            showHeader: true,
        },
        {
            field: "cellNumber",
            header: "Cellulare",
            body: "cellNumber",
            showHeader: true,
        },
        {
            field: "jobPosition",
            header: "Posizione",
            body: "jobPosition",
            showHeader: true,
        },
        {
            field: "ASLPreference",
            header: "Preferenza Asl",
            body: "ASLPreference",
            showHeader: true,
        },
        {
            field: "status",
            header: "Stato",
            body: "statusTemplate",
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: "Vedi documento",
            icon: <i className="pi pi-file" />,
            handler: openDocumentsDialog,
        },
        {
            name: "Approva",
            icon: <i className="pi pi-check" />,
            handler: approveCandidatesDialog,
        },
        {
            name: "Rifiuta",
            icon: <i className="pi pi-times" />,
            handler: rejectCandidatesDialog,
        },
    ];

    const fieldsXLSX = ["Data", "Nome", "Cognome", "Role", "Stato"]
    const pathFieldsXLSX = ["idDocument.created_at", "idRegistry.firstName", "idRegistry.lastName", "idUser.role.label", "status.label"]

    const header = (
        <div className="flex flex-row justify-between items-center">
            <Button className="buttonCustom" type="button" icon="pi pi-file-excel" label={`${width > 340 ? "Esporta XLS" : ""}`} severity="success" rounded onClick={() => exportExcel(fieldsXLSX, pathFieldsXLSX, results)} />
        </div>
    );

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300 p-3 min-[900px]:px-10`}>
                <CustomDataTable
                    dataKey="id"
                    loading={!results}
                    refer={dt}
                    value={results}
                    actionFields={actionFields}
                    fields={fields}
                    showHeader={true}
                    header={header}
                />
            </div>
            <Dialog visible={resultDialog} className="w-auto h-auto" header={"Dettagli documento"} onHide={() => setResultDialog(false)}>
                <div className="">
                    <CaricamentoDocs dialogDocs={resultDialog} setDialogDocs={setResultDialog} result={result} idRole={10} />
                </div>
            </Dialog>
        </div>
    );
}

export default Recruiting;
