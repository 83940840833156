import React, { useEffect, useRef, useState } from "react";
import { APIRequest } from "../services/axios";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { chiudimenu, resetPathCrumb } from "../store/actions/actions";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputTextarea } from "primereact/inputtextarea";
import HeadPage from "./headPage";
import { useWindowSize } from "../utils/utils";
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";

const AvviaConversazione = (props) => {
    const [messagesCopy, setMessagesCopy] = useState([]);
    const [currentMessages, setCurrentMessages] = useState([]);
    const [message, setMessage] = useState([]);
    const [uniqueUsers, setUniqueUsers] = useState([]);
    const [uniqueUsers2, setUniqueUsers2] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [page, setPage] = useState(1); // New state for pagination
    const [loading, setLoading] = useState(false); // New state to prevent multiple calls
    const toast = useRef(null);
    const role = useSelector(state => state.menu.role);
    const messagesEndRef = useRef(null);
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const isSidebarOpen = useSelector(state => state.menu.isSidebarOpen);

    useEffect(() => {
        dispatch(resetPathCrumb());
        trovaRisultato();
    }, [dispatch]);

    useEffect(() => {
        if (width > 0 && width <= 900) {
            dispatch(chiudimenu());
        }
    },[width])

    useEffect(() => {
        scrollToBottom();
    }, [currentMessages]);

    useEffect(() => {
        const chatContainer = document.getElementById("chatContainer");
        if (chatContainer) {
            chatContainer.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, [currentMessages, page, loading]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = () => {
        const chatContainer = document.getElementById("chatContainer");
        if (chatContainer.scrollTop === 0 && !loading && currentMessages.length >= 20) {
            loadMoreMessages();
        }
    };

    const loadMoreMessages = async () => {
        setLoading(true);
        const nextPage = page + 1;
        await APIRequest('GET', `notificationService/internal?id=${JSON.parse(localStorage.getItem("user")).id}&take20&skip=${nextPage}`)
            .then(res => {
                if (res.data.success) {
                    const newMessages = res.data.data.sort((a, b) => a.createAt > b.createAt ? -1 : 1);
                    setMessagesCopy(prevMessages => [...newMessages, ...prevMessages]);
                    setCurrentMessages(prevMessages => [...newMessages, ...prevMessages]);
                    setPage(nextPage);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile reperire i messaggi.`, life: 3000 });
            });
        setLoading(false);
    };

    const trovaRisultato = async () => {
        await APIRequest('GET', `notificationService/getLatestNotifications?id=${JSON.parse(localStorage.getItem("user")).id}`)
            .then(res => {
                if (res.data.success) {
                    let _messagesCopy2 = res.data.data.sort((a, b) => a.createAt > b.createAt ? -1 : 1);
                    setMessagesCopy(_messagesCopy2);
                    setCurrentMessages(_messagesCopy2);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile reperire i messaggi.`, life: 3000 });
            });
        await APIRequest('GET', `authService/user`)
            .then(res => {
                let filter = res.data.data
                if (role === "PATIENT") {
                    filter = res.data.data.filter(el => el.role.label === "OPERATOR")
                } else if (role === "OPERATOR") {
                    filter = res.data.data.filter(el => el.role.label === "SUPERVISOR" || el.role.label === "OPERATOR" || el.role.label === "PATIENT")
                } else if (role === "HR") {
                    filter = res.data.data.filter(el => el.role.label === "SUPERVISOR" || el.role.label === "HR")
                } else if (role === "ACCOUNTANT") {
                    filter = res.data.data.filter(el => el.role.label === "SUPERVISOR" || el.role.label === "ACCOUNTANT")
                } else if (role === "ADMIN") {
                    filter = res.data.data.filter(el => el.role.label === "SUPERVISOR" || el.role.label === "ADMIN")
                } else if (role === "SECRETARY") {
                    filter = res.data.data.filter(el => el.role.label === "SUPERVISOR" || el.role.label === "SECRETARY")
                } else if (role === "SUPERVISOR") {
                    filter = res.data.data.filter(el => el.role.label !== "PATIENT")
                }
                setUniqueUsers(filter);
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile reperire gli utenti.`, life: 3000 });
            });
    };

    const renderMessages = () => {
        const groupedMessages = groupConsecutiveMessages(currentMessages);

        return groupedMessages.map((group, groupIndex) => (
            <div key={groupIndex} className="flex flex-col">
                {group.map((message, index) => (
                    <div key={index}>
                        {index === 0 && groupIndex === 0 ?
                            <div>{new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(message?.createAt))}</div>
                            :
                            <>
                                {index > 0 && groupIndex === 0 ?
                                    <div>
                                        {
                                            new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(message?.createAt))
                                            !==
                                            new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(group[index - 1]?.createAt)) &&
                                            <>{new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(message?.createAt))}</>
                                        }
                                    </div>
                                    :
                                    <>
                                        {index === 0 && groupIndex > 0 &&
                                            <div>
                                                {
                                                    new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(message?.createAt))
                                                    !==
                                                    new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(groupedMessages[groupIndex - 1][groupedMessages[groupIndex - 1].length - 1].createAt)) &&
                                                    <>{new Intl.DateTimeFormat("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", }).format(new Date(message?.createAt))}</>
                                                }
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                        <div
                            className={`flex flex-col w-full p-4 ${message.idUserSender.id === JSON.parse(localStorage.getItem('user')).id ? 'items-end' : 'items-start'}`}
                        >
                            <div className={`min-w-[20%] !max-w-[50%] w-auto ${message.idUserSender.id === JSON.parse(localStorage.getItem('user')).id ? 'bg-green-200 sender message-bubble-sender' : 'bg-blue-200 receiver message-bubble-receiver'}`}>
                                <div className="text-start p-3">{message.message}</div>
                                <div className="text-right p-1 px-2 text-[#667781]">{formatTimestamp(message.createAt)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ));
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours}:${minutes.toString().length > 1 ? minutes : `0${minutes}`}`;
    };

    const groupConsecutiveMessages = (messages) => {
        const grouped = [];
        let currentGroup = [];

        messages.forEach((message, index) => {
            if (index === 0 || message.idUserSender.username !== messages[index - 1].idUserSender.username) {
                currentGroup = [message];
                grouped.push(currentGroup);
            } else {
                currentGroup.push(message);
            }
        });

        return grouped;
    };

    const changeSelection = async (e, el) => {
        await APIRequest('GET', `notificationService/internal?id=${el.id}`)
            .then(res => {
                if (res.data.success) {
                    let currentMessage = res.data.data;
                    setCurrentMessages(currentMessage);
                    setSelectedChat(el);
                    let _messagesCopy = [...messagesCopy];
                    if (currentMessage.length > 0) {
                        currentMessage.forEach(async element => {
                            if (element.visualized === false && element.idUserSender.id !== JSON.parse(window.localStorage.getItem("user"))?.id) {
                                await APIRequest('PUT', `notificationService/internal?id=${element?.id}`, element)
                                    .then(res => {
                                        let findIndex = currentMessage.findIndex(el => el.id === res.data.data.id);
                                        currentMessage[findIndex] = res.data.data;
                                        findIndex = _messagesCopy.findIndex(el => el.id === res.data.data.id);
                                        _messagesCopy[findIndex] = res.data.data;
                                        setCurrentMessages(currentMessage);
                                        setMessagesCopy(_messagesCopy);
                                    }).catch((e) => {
                                        console.log(e);
                                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile reperire i messaggi.`, life: 3000 });
                                    });
                            }
                        });
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile inviare il messaggio. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
            });
    };

    const Invia = async (e) => {
        if (message.length > 0) {
            let body = {
                idUserSender: JSON.parse(window.localStorage.getItem("user"))?.id,
                idUserReciver: selectedChat.id,
                message: message
            };
            let _messagesCopy = [...messagesCopy];
            let _currentMessages = [...currentMessages];
            await APIRequest('POST', 'notificationService/internal', body)
                .then(res => {
                    let findIndex = _messagesCopy.findIndex(el => (el.idUserSender.id === res.data.data.idUserSender.id && el.idUserReciver.id === res.data.data.idUserReciver.id) || (el.idUserSender.id === res.data.data.idUserReciver.id && el.idUserReciver.id === res.data.data.idUserSender.id));
                    if (findIndex > 0) {
                        _messagesCopy[findIndex] = res.data.data;
                    } else {
                        _messagesCopy.unshift(res.data.data);
                    }
                    _currentMessages.push(res.data.data);
                    setCurrentMessages(_currentMessages);
                    setMessagesCopy(_messagesCopy);
                    setMessage("");
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile inviare il messaggio. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                });
        }
    };

    const searchText = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filteredUsers = uniqueUsers.filter(user => {
            if (user?.idRegistry) {
                const firstName = user?.idRegistry?.firstName?.toLowerCase();
                const lastName = user?.idRegistry?.lastName?.toLowerCase();
                return firstName.includes(searchTerm) || lastName.includes(searchTerm);
            }
            return false;
        });
        setUniqueUsers2(filteredUsers);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            Invia();
        }
    };

    const indietro = () => {
        if (selectedChat) {
            setSelectedChat(null);
            setCurrentMessages([]);
        }
    }

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            {width > 500 ?
                <HeadPage />
                :
                <>
                    {!selectedChat &&
                        <HeadPage />
                    }
                </>
            }
            <div className="w-full relative h-full">
                <div className="relative w-full flex flex-row-reverse transation-all duration-200 h-full">
                    <div className={`w-5/12 min-[1300px]:w-4/12 max-[700px]:w-full ${selectedChat ? "max-[700px]:hidden" : ""} flex flex-col transation-all duration-200 relative h-full`}>
                        <div className="flex flex-row w-full sticky top-[5rem] px-2 py-4 border-b border-[#00b1b14D] bg-[#00b1b1]">
                            <IconField iconPosition="left" className="p-2 bg-[#f0f2f5] rounded-xl w-full">
                                <InputIcon className="pi pi-search w-1/12"> </InputIcon>
                                <InputText className="removeFocus w-8/12 bg-transparent placeholder:font-semibold" placeholder="Search..." onInput={(e) => searchText(e)} />
                            </IconField>
                        </div>
                        <ul className="w-full sticky top-[9.5rem] max-[899px]:h-[calc(100vh-240px)] h-[calc(100vh-180px)] overflow-y-scroll">
                            {(uniqueUsers2.length > 0 ? uniqueUsers2 : uniqueUsers).map((el, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {JSON.parse(window.localStorage.getItem("user"))?.id !== el?.id &&
                                            <>
                                                <li className={`w-full flex flex-row justify-between w-full py-2 px-1 text-lg ${selectedChat?.idRegistry?.firstName === el?.idRegistry?.firstName ? "" : "hover:bg-[#00b1b14D]"} ${selectedChat?.idRegistry?.firstName === el?.idRegistry?.firstName ? "bg-[#00b1b1] text-white" : ""}`} onClick={(e) => changeSelection(e, el)} style={{ cursor: 'pointer' }} key={el.id}>
                                                    <div className="flex flex-col w-3/4 justify-center items-start">
                                                        <div className="flex flex-row justify-between">
                                                            <p className='m-0 text-start'> {el?.idRegistry?.firstName ? el?.idRegistry?.firstName + " " + el?.idRegistry?.lastName : "null"} </p><div></div>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            {(messagesCopy.find(ele => (ele?.idUserReciver?.id === el?.id || ele?.idUserSender?.id === el?.id)) &&
                                                                messagesCopy.find(ele => (ele?.idUserReciver?.id === el?.id || ele?.idUserSender?.id === el?.id)).idUserSender.id === JSON.parse(window.localStorage.getItem("user"))?.id) &&
                                                                <>
                                                                    {messagesCopy.find(ele => (ele?.idUserReciver?.id === el?.id || ele?.idUserSender?.id === el?.id)).visualized ?
                                                                        <IoCheckmarkDoneOutline size={20} className={`mr-2 ${selectedChat?.idRegistry?.firstName === el?.idRegistry?.firstName ? "text-white" : "text-sky-500"}`} />
                                                                        :
                                                                        <IoCheckmarkOutline size={20} className="mr-2" />
                                                                    }
                                                                </>
                                                            }
                                                            <p className={`text-base text-start ${selectedChat?.idRegistry?.firstName === el?.idRegistry?.firstName ? "" : "text-[#8696a0]"}`}>{messagesCopy.find(ele => (ele?.idUserReciver?.id === el?.id || ele?.idUserSender?.id === el?.id))?.message.length > 20 ? messagesCopy.find(ele => (ele?.idUserReciver?.id === el?.id || ele?.idUserSender?.id === el?.id))?.message.slice(0, 15) + "..." : messagesCopy.find(ele => (ele?.idUserReciver?.id === el?.id || ele?.idUserSender?.id === el?.id))?.message}</p>
                                                        </div>
                                                    </div>
                                                    {messagesCopy.filter(ele => (ele?.idUserReciver?.id === JSON.parse(window.localStorage.getItem("user"))?.id && ele?.idUserSender?.id === el.id) && ele.visualized === false).length > 0 &&
                                                        <div className="w-1/4 flex flex-row justify-end items-center">
                                                            <span className={`${selectedChat?.idRegistry?.firstName === el?.idRegistry?.firstName ? "bg-[#008c8d]" : "bg-[#00b1b1]"} text-white rounded-full px-[0.6rem] p-1 text-sm`}>{messagesCopy.filter(ele => (ele?.idUserReciver?.id === JSON.parse(window.localStorage.getItem("user"))?.id && ele?.idUserSender?.id === el.id) && ele.visualized === false).length}</span>
                                                        </div>
                                                    }
                                                </li>
                                                <hr className="border-t border-[#00b1b14D]" />
                                            </>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={`${!isSidebarOpen ? "w-10/12 max-[899px]:w-9/12 max-[700px]:w-full" : "w-full"} ${selectedChat ? "" : "max-[700px]:hidden"} relative`}>
                        {selectedChat &&
                            <div className="w-full flex flex-col sticky top-[4.9rem] h-full">
                                <div className="w-full p-5 bg-[#00b1b1] h-2/12 text-start font-semibold text-2xl">
                                    {width <= 700 &&
                                        <Button className="text-white" icon="pi pi-arrow-left" onClick={() => indietro()}></Button>
                                    }
                                    <span className="text-white"> {selectedChat?.idRegistry?.firstName ? selectedChat?.idRegistry?.firstName : "null"} </span>
                                </div>
                                <div id="chatContainer" className="w-full max-[700px]:h-[calc(100vh-285px)] max-[899px]:h-[calc(100vh-320px)] h-[calc(100vh-240px)] overflow-y-scroll border-[#00b1b14D] border-r">
                                    {renderMessages()}
                                    <div ref={messagesEndRef} />
                                </div>
                                <div className='flex flex-row h-2/12 border-top py-3 px-10 border-t rounded-md border-[#00b1b14D] bg-[#00b1b1]'>
                                    <InputTextarea
                                        className='rounded mr-2 w-full pt-3 px-7 removeFocus'
                                        type='text'
                                        value={message}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder='Messaggio'
                                    />
                                    <Button
                                        className="p-3 hover:bg-[#00b1b1] rounded-full removeFocus"
                                        onClick={Invia}
                                    >
                                        <i className='pi pi-send text-white text-2xl'></i>
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvviaConversazione;
