import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { APIRequest } from '../../services/axios';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import BackgroundAnimation from '../../components/backgroundAnimation';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import logoText from "../../assets/img/logo_salus_text.png";
import { Button } from 'primereact/button';
import { useAuth } from '../../provider/useAuth';
import { useNavigate } from 'react-router-dom';
import { FaRegAddressCard, FaSignOutAlt } from 'react-icons/fa';
import { useWindowSize } from '../../utils/utils';
import { Steps } from 'primereact/steps';
import { PiReadCvLogo } from 'react-icons/pi';
import { LiaFileContractSolid } from 'react-icons/lia';

function PageCandidato() {
    let auth = useAuth();
    const navigate = useNavigate();
    const toast = useRef(null);
    const fileRef = useRef(null);
    const [width] = useWindowSize();
    const [totalSize, setTotalSize] = useState(0);
    const [fileBuffer, setFileBuffer] = useState(null);
    const [resultsTypeDocs, setResultsTypeDocs] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const [titleDoc, setTitleDoc] = useState('');
    const [resultsDocs, setResultDocs] = useState([]);

    const [activeIndex, setActiveIndex] = useState(null);

    const itemRenderer = (item, itemIndex) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? '#00b1b1' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';
        const borderColor = isActiveItem ? 'solid 0.3px #00b1b1' : 'solid 0.3px var(--text-color-secondary)'; 

        return (
            <span
                className="flex flex-row gap-2 items-center justify-center items-center rounded-full p-3 z-999"
                style={{ backgroundColor: backgroundColor, color: textColor, border: borderColor, marginTop: '-25px' }}
            >
                {itemIndex === 0 ?
                    <FaRegAddressCard size={28} className={`w-8`} />
                    :
                    itemIndex === 1 ?
                        <PiReadCvLogo size={28} className={`w-8`} />
                        :
                        itemIndex === 2 ?
                            <LiaFileContractSolid size={28} className={`w-8`} />
                            :
                            <></>
                }
                {width > 600 &&
                    item.label
                }
            </span>
        );
    };

    const items = [
        {
            label: 'Documento identità',
            template: (item) => itemRenderer(item, 0)
        },
        {
            label: 'Assicurazione Sanitaria',
            template: (item) => itemRenderer(item, 1)
        },
        {
            label: 'Contratto',
            template: (item) => itemRenderer(item, 2)
        },
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'documentsService/documentType')
                .then((res) => {
                    if (res.data.success) {
                        setResultsTypeDocs(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            await APIRequest('GET', 'documentsService')
                .then((res) => {
                    if (res.data.success) {
                        let filter = res.data.data.filter(el => el?.idUser?.id === JSON.parse(localStorage.getItem('user'))?.id);
                        setResultDocs(filter)
                        if (filter.find(el => el.document_type.name === "DocumentoIdentita")) {
                            setActiveIndex(1)
                        } else if (filter.find(el => el.document_type.name === "AssicurazioneSanitaria")) {
                            setActiveIndex(2)
                        } else if (filter.find(el => el.document_type.name === "ContrattoFirmato")) {
                            setActiveIndex(3)
                            console.log("Complete")
                        } else {
                            setActiveIndex(0)
                        }
                    } else {
                        toast.current.show({ severity: 'danger', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        trovaRisultato();
        dispatch(resetPathCrumb());
    }, [dispatch]);

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        if (e.files) {
            setTitleDoc(e.files[0].name.split(".")[0]);
            setFileUpload(e.files[0]);
            setFileBuffer(e.files[0]);  // Salva il file per la conferma di upload
        }

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Successo', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(0);
        setFileBuffer(null);
        setFileUpload(null);
        setTitleDoc('');
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setFileBuffer(null);
        setFileUpload(null);
        setTitleDoc('');
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                {chooseButton}
                {cancelButton}
                <div className="flex max-[380px]:flex-col items-center min-[380px]:gap-3 max-[400px]:w-9/12">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small className='text-gray-400'>{props.formatSize}</small>
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5"
                    style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}
                    onClick={() => {
                        const input = fileRef.current && fileRef.current.getInput();
                        if (input) input.click();
                    }}
                ></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    {activeIndex === 0 ? `Carica Documento d'identità` : (activeIndex === 1 ? "Carica Assicurazione sanitaria" : (activeIndex === 2 ? "Carica Contratto firmato" : "Carica ..."))}
                </span>
            </div>
        );
    };

    const handleConfirmUpload = async () => {
        if (fileBuffer) {
            const formData = new FormData();
            formData.append('file', new Blob([fileBuffer], { type: fileUpload?.type }), fileUpload?.name.split(" ").join("_"));
            formData.append('document_type', resultsTypeDocs.find(el => el.name === (activeIndex === 0 ? `DocumentoIdentita` : (activeIndex === 1 ? "AssicurazioneSanitaria" : "ContrattoFirmato")))?.id);
            formData.append('metadata', "{}");
            formData.append('description', "");
            formData.append('title', `${titleDoc.split(" ").join("_")}`);
            formData.append('url', "/");
            formData.append('idUser', JSON.parse(localStorage.getItem('user'))?.id);
            formData.append('path', "/");

            await APIRequest('POST', 'documentsService', formData)
                .then(res => {
                    if (res.data.success) {
                        setActiveIndex(activeIndex + 1);
                        let _resultDocs = [...resultsDocs]
                        _resultDocs.push(res.data.data)
                        setResultDocs(_resultDocs)
                        setTotalSize(0);
                        setFileBuffer(null);
                        setFileUpload(null);
                        fileRef.current.clear();
                        setTitleDoc('');
                        toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato importato con successo', life: 3000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch(e => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                });
        } else {
            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Seleziona almeno un file prima di confermare`, life: 3000 });
        }
    };

    const openDoc = async (doc) => {
        if (doc?.file && doc?.file?.data) {
            const byteCharacters = atob(doc.file.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, "_blank");
        }
    };

    const chooseOptions = { icon: 'pi pi-fw pi-plus', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined h-7 w-7 hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined h-7 w-7' };

    return (
        <>
            <BackgroundAnimation />
            <div className="flex flex-col h-full relative">
                <Toast ref={toast} />
                <div className='absolute max-[899px]:backdrop-blur-sm max-[899px]:bg-gray-500/20 flex flex-row items-center justify-between px-5 top-0 w-full z-[999] min-[900px]:mt-6'>
                    <div className="flex items-center justify-center max-[899px]:py-4 px-2">
                        <img
                            src={logoText}
                            alt="Logo"
                            className={`logo-image max-[899px]:h-9 h-12 cursor-pointer select-none`}
                        />
                    </div>
                    <div className={`max-[899px]:mb-2`}>
                        <button
                            onClick={() => {
                                auth.signout(() => navigate("/"));
                            }}
                            className="!flex items-center justify-center max-[899px]:!p-3 min-[901px]:!p-4 !w-full buttonCustom select-none gap-2"
                        >
                            <FaSignOutAlt className="select-none" size={20} />
                        </button>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center w-full h-screen'>
                    <div className={`max-[899px]:overflow-y-scroll gap-5 flex flex-col items-center min-[900px]:gap-20 items-center backdrop-blur-sm bg-gray-300/20 shadow-md w-full h-screen rounded-lg`}>
                        <Steps className='mt-32 mb-4 bg-transparent w-full' model={items} activeIndex={activeIndex} />
                        {activeIndex !== 3 &&
                            <>
                                <div className='min-[900px]:w-2/3 w-full flex flex-col items-center'>
                                    <div className={`flex bg-white flex-row items-center shadow-md rounded-lg min-[500px]:w-4/5 w-full`}>
                                        <div className='flex flex-row justify-center items-center bg-yellow-300 p-6 h-full rounded-l-lg'><i className='pi pi-exclamation-triangle text-3xl'></i></div>
                                        <div className='w-full flex flex-row items-center justify-start'>
                                            <div className='p-6 w-full flex flex-row items-center justify-center'>
                                                {activeIndex === 0 ?
                                                    <div className='flex flex-col'>
                                                        <p className='text-xl text-start'>Invia Documento d'identità</p>
                                                    </div>
                                                    :
                                                    (activeIndex === 1 ?
                                                        <div className='flex flex-col'>
                                                            <p className='text-xl text-start'>Invia Assicurazione sanitaria</p>
                                                        </div>
                                                        :
                                                        (activeIndex === 2 ?
                                                            <div className='flex flex-col'>
                                                                <p className='text-xl text-start'>Invia Contratto firmato</p>
                                                            </div>
                                                            :
                                                            <div className='flex flex-col'>
                                                                <p className='text-xl text-start'>Invia ...</p>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row max-[899px]:flex-col max-[500px]:w-full min-[900px]:w-full px-5 min-[900px]:px-10 justify-start'>
                                    <div className='flex flex-col items-center justify-between bg-white p-4 pb-0 rounded-lg min-size-[33rem] min-[900px]:max-size-[23rem]'>
                                        <FileUpload
                                            ref={fileRef}
                                            name="demo[]"
                                            url="/api/upload"
                                            accept="*"
                                            maxFileSize={1000000}
                                            onUpload={onTemplateUpload}
                                            onSelect={onTemplateSelect}
                                            onError={onTemplateClear}
                                            onClear={onTemplateClear}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            emptyTemplate={emptyTemplate}
                                            chooseOptions={chooseOptions}
                                            cancelOptions={cancelOptions}
                                        />
                                        <Button type="button" disabled={!fileBuffer} label={activeIndex === 0 ? `Invia Documento d'identità` : (activeIndex === 1 ? "Invia Assicurazione sanitaria" : (activeIndex === 2 ? "Invia Contratto firmato" : "Invia ..."))} className="buttonCustom !mb-5" onClick={handleConfirmUpload} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div >
        </>
    );
}

export default PageCandidato;



/* <div className=' flex flex-col'>
        <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona il Documento d'identità da importare</div>
        <Tooltip target=".custom-choose-btn" content="Seleziona" position="bottom" />
        <Tooltip target=".custom-upload-btn" content="Carica" position="bottom" />
        <Tooltip target=".custom-cancel-btn" content="Rimuovi" position="bottom" />

        <FileUpload ref={fileRef} name="demo[]" url="/api/upload" accept=".PDF" maxFileSize={1000000}
            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
            customUpload uploadHandler={customUploadHandler}
        />
        <div className='w-full text-start text-sm p-2'>Solo file con estensione .pdf</div>

        <Button type="button" label="Invia documenti" className="buttonCustom" onClick={handleConfirmUpload} />
    </div> */

/* <Button type="button" label="Scarica contratto" className="buttonCustom !h-full" onClick={() => openDoc(resultsDocs.find(el => el?.document_type?.name === "ContrattoNonFirmato"))} /> */

{/* <svg class="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
        <path class="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z" />
        <path class="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z" />
    </svg> */}