import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { MeterGroup } from 'primereact/metergroup';
import UserForm from '../common/userForm';
import { useDispatch, useSelector } from 'react-redux';
import { handleDialogAndBreadcrumbs, useWindowSize } from '../utils/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import CaricamentoDocs from './caricamentoDocs';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { APIRequest } from '../services/axios';
import { Toast } from 'primereact/toast';
import HeadPage from './headPage';
function Profilo(props) {
    const [width] = useWindowSize();
    const [dialogDocs, setDialogDocs] = useState(false)
    const [dialogLoadDocs, setDialogLoadDocs] = useState(false)
    const [dialogAlert, setDialogAlert] = useState(false)
    const [resultsTypeDocs, setResultsTypeDocs] = useState(null)
    const [titleDoc, setTitleDoc] = useState(null)
    const [totalSize, setTotalSize] = useState(0);
    const [fileBuffer, setFileBuffer] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const dispatch = useDispatch()
    const fileRef = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'documentsService/documentType')
                .then((res) => {
                    if (res.data.success) {
                        setResultsTypeDocs(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        trovaRisultato();
    }, []);

    const start = ({ totalPercent }) => (
        <div className="flex justify-between mt-3 mb-2 relative">
            <span className='font-semibold max-[320px]:text-lg text-xl mb-5'>Avanzamento formazione :</span>
            <span style={{ width: totalPercent + '%' }} className="absolute text-right font-semibold mt-7 text-xl">
                {totalPercent}%
            </span>
        </div>
    );

    const indietro = () => {
        handleDialogAndBreadcrumbs(props.setDialog, dispatch)
    }

    const labelList = ({ values }) => (
        <div className="flex flex-wrap gap-3 my-4">
            {values.map((item, index) => (
                <Card className="flex" key={index}>
                    <div className="flex justify-between gap-5">
                        <div className="flex flex-col gap-1">
                            <span className="text-secondary text-sm">{item.label}</span>
                            <span className="font-bold text-lg">{item.value}%</span>
                        </div>
                        <span className="w-8 h-8 border-circle inline-flex justify-center items-center text-center" style={{ backgroundColor: item.color1, color: '#ffffff' }}>
                            <i className={item.icon} />
                        </span>
                    </div>
                </Card>
            ))}
        </div>
    );

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Carica file
                </span>
            </div>
        );
    };

    const customUploadHandler = async (file) => {
        if (file) {
            setTitleDoc(file.files[0].name.split(".")[0])

            setFileUpload(file.files[0])
            setFileBuffer(file.files[0]);  // Save the file content for later upload confirmation
        }
    };

    const handleConfirmUpload = async () => {
        // Re-send the saved buffer to the backend for final processing
        if (fileBuffer) {
            if (fileRef?.current?.getFiles()[0].objectURL === fileUpload.objectURL) {
                const formData = new FormData();
                formData.append('file', new Blob([fileBuffer], { type: fileUpload.type }), fileUpload.name);
                formData.append('document_type', resultsTypeDocs.find(el => el.name === "ContrattoNonFirmato")?.id);
                formData.append('metadata', "{}")
                formData.append('description', "")
                formData.append('title', `${titleDoc}`)
                formData.append('url', "/")
                formData.append('idUser', props.result.id)
                formData.append('path', "/")

                await APIRequest('POST', 'documentsService', formData)
                    .then(res => {
                        if (res.data.success) {
                            console.log(res);
                            setDialogLoadDocs(false);
                            toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato importato con successo', life: 3000 });
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                    });
            } else {
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Carica il file prima di confermare`, life: 3000 });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Seleziona il file prima di confermare`, life: 3000 });
        }
    }

    const openDialogCaricamentoContratto = async () => {
        await APIRequest('GET', 'documentsService')
            .then((res) => {
                if (res.data.success) {
                    let filter = res.data.data.filter(el => el?.idUser?.id === props.result.id && el?.title === "CV");
                    if (filter.length > 0) {
                        setDialogAlert(true);
                    } else {
                        setDialogLoadDocs(true)
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const handleDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label="Sì" onClick={() => { setDialogAlert(false); setDialogLoadDocs(true) }} className="buttonCustom p-button-success mr-2" />
                <Button label="No" onClick={() => { setDialogAlert(false); }} className="buttonCustom p-button-secondary" />
            </React.Fragment>
        )
    }

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success rounded-full border border-green-700 p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined' };

    const values = [
        { label: 'Apps', color1: '#34d399', color: '#fbbf24', value: 25, icon: 'pi pi-table' },
        { label: 'Messages', color1: '#fbbf24', color: '#60a5fa', value: 15, icon: 'pi pi-inbox' },
        { label: 'Media', color1: '#60a5fa', color: '#c084fc', value: 20, icon: 'pi pi-image' },
        { label: 'System', color1: '#c084fc', color: '#34d399', value: 10, icon: 'pi pi-cog' }
    ];

    return (
        <div className="flex flex-col items-center w-full justify-between h-full">
            <Toast ref={toast} />
            {window.location.pathname === "/profilo" &&
                <HeadPage />
            }
            <>
                {props.result?.id !== JSON.parse(window.localStorage.getItem("user"))?.id &&
                    <div className={`max-[600px]:grid ${!props.flag ? `${JSON.parse(window.localStorage.getItem("user"))?.role?.label === "OPERATOR" ? "max-[600px]:grid-cols-3" : "max-[600px]:grid-cols-2"}` : "max-[600px]:grid-cols-1"} max-[600px]:gap-2 flex flex-row justify-between w-full p-4`}>
                        {props.dialog &&
                            <Button className='buttonCustom' label={`${width > 480 ? "Indietro" : ""}`} icon={`pi pi-arrow-left`} onClick={() => indietro()}></Button>
                        }
                        {!props.flag &&
                            <>
                                {width < 600 ?
                                    <>
                                        <Button className='buttonCustom' icon={`pi pi-download`} label={`${width > 480 ? "Esporta CSV" : "CSV"}`} ></Button>
                                        {JSON.parse(window.localStorage.getItem("user"))?.role?.label === "OPERATOR" &&
                                            <Button className='buttonCustom' icon={`pi pi-download`} label={`${width > 480 ? "Scarica CV" : "CV"}`}></Button>
                                        }
                                    </>
                                    :
                                    <div>
                                        <Button className='buttonCustom' icon={`pi pi-download`} label={`${width > 480 ? "Esporta CSV" : "CSV"}`} ></Button>
                                        {JSON.parse(window.localStorage.getItem("user"))?.role?.label === "OPERATOR" &&
                                            <Button className='buttonCustom' icon={`pi pi-download`} label={`${width > 480 ? "Scarica CV" : "CV"}`}></Button>
                                        }
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
                <div className={`min-[1201px]:px-10  flex flex-row max-[1200px]:flex-col justify-center ${props.flag ? "items-start max-[1200px]:justify-start" : "items-center"} my-4 w-full h-full`}>
                    {!props.flag &&
                        <div className='min-[1201px]:border-r max-[1200px]:border-b min-[1201px]:w-auto max-[1200px]:w-full min-[1201px]:!w-1/3 max-[1200px]:mb-5 border-[#00b1b1] h-full flex flex-col justify-center gap-8'>
                            <div className="w-full h-auto flex flex-row justify-around">
                                <div className="flex flex-row justify-center items-center">
                                    <img src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png" className="min-[1201px]:w-2/3 max-[1200px]:w-1/4" alt='Foto profilo'></img>
                                </div>
                            </div>
                            <div className='flex flex-col w-full min-[1200px]:gap-3'>
                                <Button className='buttonCustom max-[1200px]:mb-5 min-[1201px]:mx-5' label='Carica immagine'></Button>
                                {/* <Button className='buttonCustom max-[1200px]:mb-5 min-[1201px]:mx-5' label='Carica Contratto' onClick={() => openDialogCaricamentoContratto()}></Button> */}
                            </div>
                        </div>
                    }
                    <div className={`w-full ${props.flag ? "min-[1201px]:w-3/4 max-[1200px]:w-full" : "min-[1201px]:!w-2/3 max-[1200px]:w-full"} px-5`}>
                        <UserForm result={props.result} role="OPERATOR" results={props.results} setResults={props.setResults} dialog={props.dialog} setDialog={props.setDialog} idRole={props.role} />
                    </div>
                </div>
                {!props.flag &&
                    <>
                        <div className='w-full'>
                            {JSON.parse(window.localStorage.getItem("user"))?.role?.label === "OPERATOR" &&
                                <>
                                    <hr className='w-full' />
                                    <div className="w-full flex flex-row justify-around items-center my-2">
                                        <div className='w-full'>
                                            <MeterGroup className='customProgressBar' values={values} labelPosition="end" start={start} labelList={labelList} />
                                        </div>
                                    </div>
                                </>
                            }
                            <hr className='w-full' />
                        </div>
                        {(JSON.parse(window.localStorage.getItem("user"))?.role?.label === "ADMIN" || JSON.parse(window.localStorage.getItem("user"))?.role?.label === "SUPERVISOR" || JSON.parse(window.localStorage.getItem("user"))?.role?.label === "HR") &&
                            <div className='w-full'>
                                <div className='flex flex-row justify-center my-8'>
                                    <Button className='buttonCustom min-[400px]:!w-1/3 max-[400px]:!w-full' label='Documenti' onClick={() => setDialogDocs(true)}></Button>
                                    {(JSON.parse(window.localStorage.getItem("user"))?.role?.label === "ADMIN" || JSON.parse(window.localStorage.getItem("user"))?.role?.label === "SUPERVISOR") &&
                                        <Button className='buttonCustom min-[400px]:!w-1/3 max-[400px]:!w-full' label='Storico attività'></Button>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
            </>
            <Dialog className='text-fluid1 max-w-[70vh]' visible={dialogAlert} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={() => <><i className="pi pi-exclamation-triangle mr-3" />Avviso</>} modal footer={handleDialogFooter} onHide={() => setDialogAlert(false)}>
                <div className="flex flex-col items-start justify-center">
                    <p className='text-fluid1'>Hai già caricato un contratto per questo utente, vuoi caricarne un altro?</p>
                    <p className='text-sm'><b className='text-red-400'>ATTENZIONE:</b> Caricando un nuovo contratto il vecchio contratto associato verrà sostituito</p>
                </div>
            </Dialog>
            <Dialog className='text-fluid1 max-w-[70vh]' visible={dialogDocs} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={() => <><i className="pi pi-file mr-3" />Documenti utente</>} modal onHide={() => setDialogDocs(false)}>
                <div className="">
                    <CaricamentoDocs dialogDocs={dialogDocs} setDialogDocs={setDialogDocs} result={props.result} idRole={props.role} />
                </div>
            </Dialog>
            <Dialog className='text-fluid1' visible={dialogLoadDocs} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={() => <><i className="pi pi-file mr-3" />Caricamento Contratto</>} modal onHide={() => setDialogLoadDocs(false)}>
                <div className="">
                    <div className=' flex flex-col'>
                        <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona il file da importare</div>
                        <Tooltip target=".custom-choose-btn" content="Seleziona" position="bottom" />
                        <Tooltip target=".custom-upload-btn" content="Carica" position="bottom" />
                        <Tooltip target=".custom-cancel-btn" content="Rimuovi" position="bottom" />

                        <FileUpload ref={fileRef} name="demo[]" url="/api/upload" accept=".PDF" maxFileSize={1000000}
                            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                            customUpload uploadHandler={customUploadHandler}
                        />
                        <div className='w-full text-start text-sm p-2'>Solo file con estensione .pdf</div>
                        <Button type="button" label="Conferma" className="buttonCustom" onClick={handleConfirmUpload} />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Profilo;
