import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APIRequest } from "../../services/axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CustomDataTable from "../../components/customDataTable";
import HeadPage from "../../components/headPage";
import Profilo from "../../components/profile";
import { resetPathCrumb, setPathCrumb } from "../../store/actions/actions";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { exportExcel, useWindowSize } from "../../utils/utils";

function Supervisore() {
    const dt = useRef(null);
    const toast = useRef(null);
    const dispatch = useDispatch()
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const [flag, setFlag] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [deleteResultDialog, setDeleteResultDialog] = useState(false);
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'authService/user?role=SUPERVISOR')
                .then(res => {
                    setResults(res.data.data);
                }).catch((e) => {
                    console.log(e)
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    const openDialog = (rowData) => {
        setDialog(true)
        dispatch(setPathCrumb(
            {
                "label": "Info Profilo",
                "props": {
                    results: results,
                    dialog: true,
                    result: rowData,
                    flag: false
                },
                "template": () => <NavLink to={"/supervisore"} onClick={() => {setDialog(true)}}>Info Profilo</NavLink>
            }
        ))
        setFlag(false)
        setResult(rowData)
    }

    const openDialog2 = (rowData) => {
        setResult(rowData)
        setDeleteResultDialog(true)
    }

    const hideDeleteResultDialog = () => {
        setDeleteResultDialog(false);
    };

    const deleteResult = async () => {

        await APIRequest("DELETE", `authService/user-registry?id=${result?.id}&idRegistry=${result?.idRegistry?.id}`)
            .then(res => {
                let _results = results.filter((val) => val.id !== result.id);

                setResults(_results);
                setDeleteResultDialog(false);
                setResult(null);
                toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: "L'utente è stato eliminato correttamente", life: 3000 });
            }).catch((e) => {
                console.log(e)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile eliminare l'utente", life: 3000 });
            })
    };

    const manageUser = (rowData, isNew) => {
        setDialog(true)
        setFlag(true)
        setResult(!isNew ? rowData : null)
        dispatch(setPathCrumb(
            {
                "label": "Nuovo supervisore",
                "props": {
                    dialog: true,
                    result: !isNew ? rowData : null,
                    flag: true
                },
                "template": () => <NavLink to={"/supervisore"} onClick={() => {setDialog(true)}}>Nuovo supervisore</NavLink>
            }
        ))
    }

    const deleteResultDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="buttonCustom" outlined onClick={hideDeleteResultDialog} />
            <Button label="Si" icon="pi pi-check" className="buttonCustom" severity="danger" onClick={deleteResult} />
        </React.Fragment>
    );

    const fields = [
        {
            field: "firstName",
            header: "Nome",
            body: "firstName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "lastName",
            header: "Cognome",
            body: "lastName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "username",
            header: "Email",
            sortable: true,
            showHeader: true,
        },
        {
            field: "role",
            header: "Role",
            body: "roleTemplate",
            sortable: true,
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: "Info",
            icon: <i className="pi pi-eye" />,
            handler: openDialog,
        },
        {
            name: "Delete",
            icon: <i className="pi pi-trash" />,
            handler: openDialog2,
        },
    ];

    const fieldsXLSX = ["Nome","Cognome","Email","Role"]
    const pathFieldsXLSX = ["idRegistry.firstName","idRegistry.lastName","username","role.label"]

    const header = (
        <div className="flex flex-row justify-between items-center">
            <Button className="buttonCustom" type="button" icon="pi pi-file-excel" label={`${width > 340 ? "Esporta XLS" : ""}`} severity="success" rounded onClick={() => exportExcel(fieldsXLSX, pathFieldsXLSX, results)}/>
            <Button className="buttonCustom" type="button" label={`${width > 340 ? "Nuovo" : ""}`} icon="pi pi-plus" rounded onClick={(rowData) => manageUser(rowData, true)} tooltip="Aggiungi supervisore" data-pr-tooltip="Aggiungi supervisore" tooltipOptions={{ position: 'top' }} />
        </div>
    );

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage setDialog={setDialog}/>
            <div className={`flex-grow transition-all duration-300`}>
                {dialog ? (
                    <div className="min-[900px]:px-10 p-3 w-full h-full flex flex-row items-center justify-start ">
                        <div className={`h-full ${flag && "w-full"}`}>
                            <Profilo result={result} dialog={dialog} setDialog={setDialog} results={results} setResults={setResults} flag={flag} setFlag={setFlag} role={6}/>
                        </div>
                    </div>
                ) : (
                    <div className="p-3 min-[900px]:px-10 h-full flex flex-col justify-start items-center">
                        <CustomDataTable
                            dataKey="username"
                            loading={!results}
                            refer={dt}
                            value={results}
                            actionFields={actionFields}
                            fields={fields}
                            showHeader={true}
                            header={header}
                        />
                    </div>
                )}
            </div>
            <Dialog visible={deleteResultDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={() => <><i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1rem' }} />Conferma cancellazione</>} modal footer={deleteResultDialogFooter} onHide={hideDeleteResultDialog}>
                <div className="confirmation-content">
                    {result && (
                        <>
                            <span>
                                Vuoi davvero eliminare l'utente <b>{`${result?.idRegistry?.firstName} ${result?.idRegistry?.lastName} `}</b>
                            </span>
                            <br />
                            <span>
                                Con email: <b>{`${result?.username}`}</b>?
                            </span>
                        </>
                    )}
                </div>
            </Dialog>
        </div>
    );
}

export default Supervisore;
